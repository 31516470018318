import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import type {
  AIModel,
  FeatureModelSetting,
  NewModel,
} from '../types/ai-models';

const AI_MODELS_KEY = 'ai-models';
const FEATURE_MODELS_KEY = 'feature-models';

export function useAIModels() {
  const api = useApi();

  return useQuery({
    queryKey: [AI_MODELS_KEY],
    queryFn: async () => {
      const response = await api.get<{ success: boolean; models: AIModel[] }>(
        '/settings/ai-models'
      );
      if (!response.success) {
        throw new Error('Failed to fetch AI models');
      }
      return response.models;
    },
  });
}

export function useFeatureModels() {
  const api = useApi();

  return useQuery({
    queryKey: [FEATURE_MODELS_KEY],
    queryFn: async () => {
      const response = await api.get<{
        success: boolean;
        settings: FeatureModelSetting[];
      }>('/settings/feature-models');
      if (!response.success) {
        throw new Error('Failed to fetch feature models');
      }
      return response.settings;
    },
  });
}

export function useAddAIModel() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newModel: NewModel) => {
      const response = await api.post<{ success: boolean; model: AIModel }>(
        '/settings/ai-models',
        newModel
      );
      if (!response.success) {
        throw new Error('Failed to add AI model');
      }
      return response.model;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [AI_MODELS_KEY] });
    },
  });
}

export function useDeleteAIModel() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (modelId: string) => {
      const response = await api.delete<{ success: boolean }>(
        `/settings/ai-models/${modelId}`
      );
      if (!response.success) {
        throw new Error('Failed to delete AI model');
      }
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [AI_MODELS_KEY] });
    },
  });
}

export function useUpdateFeatureModel() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      feature,
      modelId,
    }: {
      feature: string;
      modelId: string;
    }) => {
      const response = await api.patch<{
        success: boolean;
        setting: FeatureModelSetting;
      }>(`/settings/feature-models/${feature}`, { model_id: modelId });
      if (!response.success) {
        throw new Error('Failed to update feature model');
      }
      return response.setting;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FEATURE_MODELS_KEY] });
    },
  });
}
