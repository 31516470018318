import { Switch } from '@/components/ui/switch';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useHSLookup } from './HSLookup/hooks/useHSLookup';
import { useBulkUpload } from './HSLookup/hooks/useBulkUpload';
import { useFeedback } from './HSLookup/hooks/useFeedback';
import { HSLookupForm } from './HSLookup/components/HSLookupForm';
import { BulkUploadSection } from './HSLookup/components/BulkUploadSection';
import { ClassificationResults } from './HSLookup/components/ClassificationResults';
import { FeedbackDialog } from './HSLookup/components/FeedbackDialog';
import { ErrorDisplay } from './HSLookup/components/ErrorDisplay';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Upload, Loader2 } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';

export default function HSLookup() {
  const {
    form,
    result,
    isLoading,
    error,
    isDetailedMode,
    customProductType,
    isCustomProductType,
    cascadeState,
    onSubmit,
    handleClear,
    handleProductTypeChange,
    handleCustomProductTypeChange,
    setIsDetailedMode,
    refreshResult,
  } = useHSLookup();

  const {
    selectedFile,
    isBulkLoading,
    recentBulkUploads,
    handleFileSelect,
    handleBulkUpload,
    refreshBulkUploads,
    bulkUploadForm,
    downloadBulkResult,
    deleteBulkJob,
  } = useBulkUpload(form.getValues('hs_digits') || '8');

  const {
    showFeedbackDialog,
    feedbackData,
    setShowFeedbackDialog,
    handleFeedbackSubmit,
    updateFeedbackMetric,
    updateComments,
  } = useFeedback(result?.code);

  return (
    <div className="container mx-auto p-4">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-3xl font-bold">HS Code Lookup</h1>
        <div className="flex items-center gap-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div className="flex items-center gap-2">
                  <span className="text-sm text-gray-600">
                    {isDetailedMode ? 'Detailed' : 'Simplified'}
                  </span>
                  <Switch
                    checked={isDetailedMode}
                    onCheckedChange={setIsDetailedMode}
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Simplified is for quick classification of simple goods</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Column */}
        <div className="space-y-6">
          <HSLookupForm
            form={form}
            isLoading={isLoading}
            customProductType={customProductType}
            isCustomProductType={isCustomProductType}
            onSubmit={onSubmit}
            handleClear={handleClear}
            handleProductTypeChange={handleProductTypeChange}
            handleCustomProductTypeChange={handleCustomProductTypeChange}
          />

          <BulkUploadSection
            form={bulkUploadForm}
            selectedFile={selectedFile}
            isBulkLoading={isBulkLoading}
            onFileSelect={handleFileSelect}
            onBulkUpload={handleBulkUpload}
            recentBulkUploads={recentBulkUploads}
            onDownload={downloadBulkResult}
            onDelete={deleteBulkJob}
          />
        </div>

        {/* Right Column */}
        <div className="space-y-6">
          {result && (
            <ClassificationResults
              result={result}
              hsDigits={form.getValues('hs_digits') || '8'}
              cascadeState={cascadeState}
              onFeedbackClick={() => setShowFeedbackDialog(true)}
            />
          )}
        </div>
      </div>

      {error && (
        <ErrorDisplay
          error={error}
          onRetry={() => {
            const values = form.getValues();
            onSubmit(values);
          }}
        />
      )}

      <FeedbackDialog
        open={showFeedbackDialog}
        onOpenChange={setShowFeedbackDialog}
        feedbackData={feedbackData}
        onSubmit={handleFeedbackSubmit}
        updateFeedbackMetric={updateFeedbackMetric}
        updateComments={updateComments}
      />
    </div>
  );
}
