import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryClient } from '@/lib/queryClient';
import { getApiUrl } from '../config';
import { useAuth } from '@/contexts/AuthContext';
import { fetchApi, uploadFile } from '../api/utils/fetch';
import { debugLog } from '../debug';

export interface UploadFCLRateData {
  carrier_name: string;
  validity_from: string;
  validity_to: string;
  file: File;
  file_key?: string;
}

export interface FCLRateHistory {
  id: string;
  carrier_name: string;
  validity_from: string;
  validity_to: string;
  file_name: string;
  upload_date: string;
  rates_key: string;
  status: string;
  error_message: string | null;
  is_archived: boolean;
  created_at: string;
  updated_at: string;
  file_key: string;
}

export interface UploadResponse {
  id: string;
  historyId: string;
}

export interface ProcessingStatus {
  status: string;
  error_message: string | null;
}

export interface ArchiveRequest {
  id: string;
  file_key: string;
}

export function useUploadFCLRate() {
  const queryClient = useQueryClient();
  const { token } = useAuth();

  return useMutation({
    mutationFn: async ({
      carrier_name,
      validity_from,
      validity_to,
      file,
      file_key,
    }: UploadFCLRateData) => {
      debugLog.request('POST', 'fcl/rates', {
        Authorization: `Bearer ${token}`,
      });

      const formData = new FormData();
      formData.append('file', file);
      formData.append('carrier_name', carrier_name);
      formData.append('validity_from', validity_from);
      formData.append('validity_to', validity_to);
      if (file_key) {
        formData.append('file_key', file_key);
      }

      try {
        const response = await uploadFile<UploadResponse>(
          'fcl/rates',
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        debugLog.response(
          'fcl/rates',
          200,
          new Headers(),
          JSON.stringify(response)
        );
        return response;
      } catch (error) {
        debugLog.error('FCL Rate Upload Failed', error);
        if (error instanceof Error) {
          throw new Error(`Upload failed: ${error.message}`);
        }
        throw new Error('Upload failed: Network error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fclRateHistory'] });
    },
  });
}

export function useDeleteFCLRate() {
  const queryClient = useQueryClient();
  const { token } = useAuth();

  return useMutation({
    mutationFn: async (id: string) => {
      try {
        const response = await fetchApi<{ success: boolean }>(
          `fcl/rates/${id}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        return response;
      } catch (error) {
        debugLog.error('FCL Rate Delete Failed', error);
        if (error instanceof Error) {
          throw new Error(`Delete failed: ${error.message}`);
        }
        throw new Error('Delete failed: Network error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fclRateHistory'] });
    },
  });
}

export function useArchiveFCLRate() {
  const queryClient = useQueryClient();
  const { token } = useAuth();

  return useMutation({
    mutationFn: async ({ id, file_key }: ArchiveRequest) => {
      try {
        const response = await fetchApi<{ success: boolean }>(
          `fcl/rates/${id}/archive`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ file_key }),
          }
        );

        return response;
      } catch (error) {
        debugLog.error('FCL Rate Archive Failed', error);
        if (error instanceof Error) {
          throw new Error(`Archive failed: ${error.message}`);
        }
        throw new Error('Archive failed: Network error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fclRateHistory'] });
    },
  });
}

export function useFCLRateHistory() {
  const { token } = useAuth();

  return useQuery<FCLRateHistory[]>({
    queryKey: ['fclRateHistory'],
    queryFn: async () => {
      try {
        const response = await fetchApi<{
          success: boolean;
          rates: FCLRateHistory[];
        }>('fcl/rates/history', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return response.rates;
      } catch (error) {
        debugLog.error('FCL Rate History Fetch Failed', error);
        if (error instanceof Error) {
          throw new Error(`Failed to fetch rate history: ${error.message}`);
        }
        throw new Error('Failed to fetch rate history: Network error');
      }
    },
  });
}

export function useProcessingStatus(historyId: string) {
  const { token } = useAuth();

  return useQuery<ProcessingStatus>({
    queryKey: ['processingStatus', historyId],
    queryFn: async () => {
      if (!historyId) return { status: 'unknown', error_message: null };

      try {
        const response = await fetchApi<ProcessingStatus>(
          `fcl/rates/status/${historyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        return response;
      } catch (error) {
        debugLog.error('FCL Rate Status Check Failed', error);
        if (error instanceof Error) {
          throw new Error(
            `Failed to fetch processing status: ${error.message}`
          );
        }
        throw new Error('Failed to fetch processing status: Network error');
      }
    },
    enabled: !!historyId && !!token,
    refetchInterval: data => {
      if (!data) return false;
      return data.status === 'processing' ? 5000 : false;
    },
    refetchIntervalInBackground: true,
    retry: 3,
  });
}

export function useDownloadFCLRateFile() {
  const { token } = useAuth();

  return useMutation({
    mutationFn: async (ratesKey: string) => {
      try {
        const response = await fetchApi<Blob>(`fcl/rates/${ratesKey}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/octet-stream',
          },
        });

        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = ratesKey.split('/').pop() || 'rates.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        debugLog.error('FCL Rate Download Failed', error);
        if (error instanceof Error) {
          throw new Error(`Download failed: ${error.message}`);
        }
        throw new Error('Download failed: Network error');
      }
    },
  });
}

export async function checkProcessingStatus(
  historyId: string
): Promise<ProcessingStatus> {
  const { token } = useAuth();
  try {
    const response = await fetchApi<ProcessingStatus>(
      `fcl/rates/status/${historyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    debugLog.error('FCL Rate Status Check Failed', error);
    if (error instanceof Error) {
      throw new Error(`Status check failed: ${error.message}`);
    }
    throw new Error('Status check failed: Network error');
  }
}
