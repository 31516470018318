interface DebugConfig {
  enableReactDevTools: boolean;
  enableViteDebug: boolean;
  enableAuthDebug: boolean;
  enableApiDebug: boolean;
}

// Load debug config from localStorage or use defaults
const loadDebugConfig = (): DebugConfig => {
  const stored = localStorage.getItem('debug-config');
  if (stored) {
    try {
      return JSON.parse(stored);
    } catch (e) {
      console.error('Failed to parse debug config:', e);
    }
  }
  return {
    enableReactDevTools: false,
    enableViteDebug: false,
    enableAuthDebug: true,
    enableApiDebug: true,
  };
};

// Save debug config to localStorage
const saveDebugConfig = (config: DebugConfig) => {
  localStorage.setItem('debug-config', JSON.stringify(config));
};

export const debugConfig = loadDebugConfig();

// Enable/disable React DevTools
export const toggleReactDevTools = (enable?: boolean) => {
  const newState = enable ?? !debugConfig.enableReactDevTools;
  debugConfig.enableReactDevTools = newState;
  saveDebugConfig(debugConfig);
  
  if (newState) {
    // @ts-ignore
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function(obj: any) {
      return obj;
    };
  } else {
    // @ts-ignore
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__ = undefined;
  }
};

// Enable/disable Vite Debug
export const toggleViteDebug = (enable?: boolean) => {
  const newState = enable ?? !debugConfig.enableViteDebug;
  debugConfig.enableViteDebug = newState;
  saveDebugConfig(debugConfig);
  
  if (newState) {
    localStorage.setItem('debug', 'vite:*');
  } else {
    localStorage.removeItem('debug');
  }
};

// Toggle auth debugging
export const toggleAuthDebug = (enable?: boolean) => {
  debugConfig.enableAuthDebug = enable ?? !debugConfig.enableAuthDebug;
  saveDebugConfig(debugConfig);
};

// Toggle API debugging
export const toggleApiDebug = (enable?: boolean) => {
  debugConfig.enableApiDebug = enable ?? !debugConfig.enableApiDebug;
  saveDebugConfig(debugConfig);
};
