import { User } from '@/types/user';

export const PERMISSIONS = {
  CONVERT_TO_EXCEL: 'convert_to_excel',
  HS_LOOKUP: 'hs_lookup',
  FCL_SEARCH: 'fcl_search',
  DOC_CHECK: 'doc_check',
  CHAT: 'chat',
  MANAGE_USERS: 'manage_users',
  MANAGE_SETTINGS: 'manage_settings',
  SUPERADMIN: 'superadmin',
} as const;

export type Permission = (typeof PERMISSIONS)[keyof typeof PERMISSIONS];

// Define page access permissions
export const PAGE_PERMISSIONS = {
  dashboard: [], // Everyone can access dashboard
  convert: [PERMISSIONS.CONVERT_TO_EXCEL],
  hsLookup: [PERMISSIONS.HS_LOOKUP],
  fclSearch: [PERMISSIONS.FCL_SEARCH],
  docCheck: [PERMISSIONS.DOC_CHECK],
  chat: [PERMISSIONS.CHAT],
  users: [PERMISSIONS.MANAGE_USERS],
  settings: [PERMISSIONS.MANAGE_SETTINGS],
  superadmin: [PERMISSIONS.SUPERADMIN],
} as const;

// Default permissions for each role
export const ROLE_PERMISSIONS: Record<string, Permission[]> = {
  admin: [
    PERMISSIONS.CONVERT_TO_EXCEL,
    PERMISSIONS.HS_LOOKUP,
    PERMISSIONS.FCL_SEARCH,
    PERMISSIONS.DOC_CHECK,
    PERMISSIONS.CHAT,
    PERMISSIONS.MANAGE_USERS,
    PERMISSIONS.MANAGE_SETTINGS,
    PERMISSIONS.SUPERADMIN,
  ],
  user: [
    PERMISSIONS.CONVERT_TO_EXCEL,
    PERMISSIONS.HS_LOOKUP,
    PERMISSIONS.FCL_SEARCH,
    PERMISSIONS.DOC_CHECK,
    PERMISSIONS.CHAT,
  ],
};

// Check if user has permission to access a page
export function hasPageAccess(
  user: User | null,
  page: keyof typeof PAGE_PERMISSIONS
): boolean {
  if (!user) return false;

  // Superadmin has access to everything
  if (user.is_superadmin) return true;

  // Admins have access to everything except superadmin pages
  if (user.role === 'admin' && page !== 'superadmin') return true;

  // For dashboard or pages without specific permissions
  if (PAGE_PERMISSIONS[page].length === 0) return true;

  // Check if user has any of the required permissions
  const userPermissions = getUserPermissions(user);
  return PAGE_PERMISSIONS[page].some(permission =>
    userPermissions.includes(permission)
  );
}

export function formatPermission(permission: Permission): string {
  return permission
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function getUserPermissions(user: User): Permission[] {
  if (!user) return [];

  // Start with role-based permissions
  const rolePermissions = ROLE_PERMISSIONS[user.role] || [];

  // Add custom permissions if any
  let customPermissions: Permission[] = [];
  if (user.permissions) {
    // If permissions is already an array, use it directly
    if (Array.isArray(user.permissions)) {
      customPermissions = user.permissions.filter((p): p is Permission =>
        Object.values(PERMISSIONS).includes(p as Permission)
      );
    } else if (typeof user.permissions === 'string') {
      try {
        // Only try to parse if it looks like a JSON array
        if (user.permissions.trim().startsWith('[')) {
          const parsed = JSON.parse(user.permissions);
          if (Array.isArray(parsed)) {
            customPermissions = parsed.filter((p): p is Permission =>
              Object.values(PERMISSIONS).includes(p as Permission)
            );
          }
        } else {
          // Single permission string
          if (
            Object.values(PERMISSIONS).includes(user.permissions as Permission)
          ) {
            customPermissions = [user.permissions as Permission];
          }
        }
      } catch (error) {
        console.error('Error parsing permissions:', error);
        customPermissions = [];
      }
    }
  }

  return [...new Set([...rolePermissions, ...customPermissions])];
}
