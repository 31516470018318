import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  FileSpreadsheet,
  Search,
  Ship,
  FileCheck,
  Users,
  ArrowUpRight,
  Loader2,
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { useRecentConversions } from '@/lib/api/services/conversion';
import { useHSLookup } from '@/pages/HSLookup/hooks/useHSLookup';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useInterval } from '@/hooks/useInterval';
import { ConversionJob } from '@/lib/api/types/conversion';
import { useDashboardStats } from '@/lib/api/services/dashboard';

export default function Dashboard() {
  // Fetch dashboard stats
  const { data: dashboardStats, isLoading: isStatsLoading } =
    useDashboardStats();

  // Fetch recent conversions
  const {
    data: recentConversions,
    isLoading: isConversionsLoading,
    refetch: refreshConversions,
  } = useRecentConversions(3, 0);

  // Fetch HS lookups
  const { result: hsResult, refreshResult: refreshHSLookup } = useHSLookup();

  // Poll for updates
  useInterval(() => {
    if (
      recentConversions?.jobs.some(
        (job: ConversionJob) =>
          job.status === 'pending' || job.status === 'processing'
      )
    ) {
      refreshConversions();
    }
    if (hsResult) {
      refreshHSLookup();
    }
  }, 5000);

  const stats = [
    {
      title: 'Total Shipments',
      value: dashboardStats?.totalShipments || '0',
      icon: FileSpreadsheet,
      href: '/shipments',
      isLoading: isStatsLoading,
    },
    {
      title: 'Active Shipments',
      value: dashboardStats?.activeShipments || '0',
      icon: Search,
      href: '/shipments?status=active',
      isLoading: isStatsLoading,
    },
    {
      title: 'Completed Shipments',
      value: dashboardStats?.completedShipments || '0',
      icon: Ship,
      href: '/shipments?status=completed',
      isLoading: isStatsLoading,
    },
    {
      title: 'Pending Shipments',
      value: dashboardStats?.pendingShipments || '0',
      icon: FileCheck,
      href: '/shipments?status=pending',
      isLoading: isStatsLoading,
    },
  ];

  return (
    <div className="space-y-6 container mx-auto px-4 py-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold tracking-tight">Dashboard</h1>
        <Card className="w-auto bg-primary/10">
          <CardContent className="py-2 px-4">
            <div className="flex items-center gap-2 text-primary">
              <Users size={16} />
              <span className="font-medium">5 Active Users</span>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {stats.map(stat => {
          const Icon = stat.icon;
          return (
            <Link key={stat.href} to={stat.href} className="block">
              <Card className="hover:bg-accent/5 cursor-pointer transition-colors border-border/40">
                <CardHeader className="flex flex-row items-center justify-between pb-2">
                  <CardTitle className="text-sm font-medium">
                    {stat.title}
                  </CardTitle>
                  <Icon size={16} className="text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="flex justify-between items-center">
                    {stat.isLoading ? (
                      <Loader2 className="h-6 w-6 animate-spin" />
                    ) : (
                      <div className="text-2xl font-bold">{stat.value}</div>
                    )}
                    <ArrowUpRight size={20} className="text-accent" />
                  </div>
                  <p className="text-xs text-muted-foreground">
                    +12% from last month
                  </p>
                </CardContent>
              </Card>
            </Link>
          );
        })}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Recent Conversions */}
        <Card className="border-border/40 lg:col-span-1">
          <CardHeader>
            <CardTitle className="text-lg font-semibold">
              Recent Conversions
            </CardTitle>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[300px]">
              <div className="space-y-4">
                {isConversionsLoading ? (
                  <div className="flex justify-center py-4">
                    <Loader2 className="h-6 w-6 animate-spin" />
                  </div>
                ) : recentConversions?.jobs?.length === 0 ? (
                  <p className="text-center text-muted-foreground py-4">
                    No recent conversions
                  </p>
                ) : (
                  recentConversions?.jobs.map((job: ConversionJob) => (
                    <div
                      key={job.id}
                      className="flex items-center justify-between py-2 border-b last:border-0 border-border/40"
                    >
                      <div>
                        <p className="font-medium">{job.originalFilename}</p>
                        <p className="text-sm text-muted-foreground">
                          Status: {job.status}
                        </p>
                      </div>
                      <span className="text-sm text-muted-foreground">
                        {new Date(job.createdAt).toLocaleString()}
                      </span>
                    </div>
                  ))
                )}
              </div>
            </ScrollArea>
          </CardContent>
        </Card>

        {/* Recent HS Lookups */}
        <Card className="border-border/40 lg:col-span-1">
          <CardHeader>
            <CardTitle className="text-lg font-semibold">
              Recent HS Lookups
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {[1, 2, 3].map(i => (
                <Link
                  key={i}
                  to={`/hs-lookup?product=Product ${i}&code=8471.30.${i}0`}
                  className="block"
                >
                  <div className="flex items-center justify-between py-2 border-b last:border-0 border-border/40 hover:bg-accent/5 rounded-md px-2 transition-colors">
                    <div>
                      <p className="font-medium">Product {i}</p>
                      <p className="text-sm text-muted-foreground">
                        HS Code: 8471.30.{i}0
                      </p>
                    </div>
                    <span className="text-sm text-muted-foreground">
                      3h ago
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </CardContent>
        </Card>

        {/* Recent FCL Searches */}
        <Card className="border-border/40 lg:col-span-1">
          <CardHeader>
            <CardTitle className="text-lg font-semibold">
              Recent FCL Searches
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {[
                {
                  query: '20ft container from Shanghai to Sydney',
                  timestamp: '2h ago',
                  results: '5 rates found',
                },
                {
                  query: '40ft container from Singapore to Melbourne',
                  timestamp: '4h ago',
                  results: '8 rates found',
                },
                {
                  query: '40HC from Hong Kong to Brisbane',
                  timestamp: '6h ago',
                  results: '3 rates found',
                },
              ].map((search, i) => (
                <Link
                  key={i}
                  to={`/fcl-search?query=${encodeURIComponent(search.query)}`}
                  className="block"
                >
                  <div className="flex items-center justify-between py-2 border-b last:border-0 border-border/40 hover:bg-accent/5 rounded-md px-2 transition-colors">
                    <div>
                      <p className="font-medium">{search.query}</p>
                      <p className="text-sm text-muted-foreground">
                        {search.results}
                      </p>
                    </div>
                    <span className="text-sm text-muted-foreground">
                      {search.timestamp}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
