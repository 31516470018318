import { useCallback } from 'react';
import { apiClient } from '@/lib/api-client';
import { useNavigate } from 'react-router-dom';

interface UseApiOptions {
  onError?: (error: Error) => void;
}

export function useApi(options: UseApiOptions = {}) {
  const navigate = useNavigate();

  const handleError = useCallback(
    (error: Error) => {
      if (error.message.includes('Unauthorized')) {
        navigate('/login');
      }
      options.onError?.(error);
    },
    [options.onError, navigate]
  );

  return {
    get: async <T>(endpoint: string) => {
      try {
        return await apiClient.get<T>(endpoint);
      } catch (error) {
        handleError(
          error instanceof Error ? error : new Error('Network error')
        );
        throw error;
      }
    },
    post: async <T>(endpoint: string, data?: unknown) => {
      try {
        return await apiClient.post<T>(endpoint, data);
      } catch (error) {
        handleError(
          error instanceof Error ? error : new Error('Network error')
        );
        throw error;
      }
    },
    put: async <T>(endpoint: string, data?: unknown) => {
      try {
        return await apiClient.put<T>(endpoint, data);
      } catch (error) {
        handleError(
          error instanceof Error ? error : new Error('Network error')
        );
        throw error;
      }
    },
    patch: async <T>(endpoint: string, data?: unknown) => {
      try {
        return await apiClient.patch<T>(endpoint, data);
      } catch (error) {
        handleError(
          error instanceof Error ? error : new Error('Network error')
        );
        throw error;
      }
    },
    delete: async <T>(endpoint: string) => {
      try {
        return await apiClient.delete<T>(endpoint);
      } catch (error) {
        handleError(
          error instanceof Error ? error : new Error('Network error')
        );
        throw error;
      }
    },
  };
}
