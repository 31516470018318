import { ScrollArea } from '@/components/ui/scroll-area';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ThumbsUp } from 'lucide-react';
import type { HSResult, CascadeState } from '@/lib/api/types/hs-lookup';
import { formatHSCode } from '../utils/index';

interface ClassificationResultsProps {
  result: HSResult;
  lookup_id?: string;
  hsDigits: string;
  cascadeState: CascadeState;
  onFeedbackClick: () => void;
}

export function ClassificationResults({
  result,
  lookup_id,
  hsDigits,
  cascadeState,
  onFeedbackClick,
}: ClassificationResultsProps) {
  const renderCascadeProgress = () => {
    return (
      <div className="space-y-2">
        <div className="text-sm font-medium">
          {cascadeState.stage === 'initial' && 'Initial Analysis...'}
          {cascadeState.stage === 'detailed' && 'Detailed Review...'}
          {cascadeState.stage === 'validation' && 'Final Validation...'}
          {cascadeState.stage === 'complete' && 'Complete'}
        </div>
        <div className="w-full bg-secondary h-2 rounded-full">
          <div
            className="bg-primary h-2 rounded-full transition-all duration-300"
            style={{ width: `${cascadeState.progress}%` }}
          />
        </div>
      </div>
    );
  };

  const formatTradeConsiderationValue = (key: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
      // Handle seasonal requirements object
      if (key === 'seasonal_requirements') {
        return Object.entries(value)
          .map(
            ([k, v]) =>
              `${k
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}: ${v}`
          )
          .join('\n');
      }
      return JSON.stringify(value);
    }

    if (key === 'duty_rates') {
      // Remove percentage signs from duty rates
      return value.replace(/%/g, '');
    }
    return value;
  };

  return (
    <Card className="border-[#D1D5DB]">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
        <CardTitle className="text-lg font-semibold">
          Classification Results
        </CardTitle>
        <div className="flex space-x-2">
          <Button
            variant="outline"
            size="sm"
            className="flex items-center gap-1"
            onClick={onFeedbackClick}
          >
            <ThumbsUp className="h-4 w-4" />
            Rate Classification
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        {renderCascadeProgress()}
        <div className="space-y-6">
          {/* Primary Classification */}
          <div className="space-y-4">
            <div className="p-4 bg-accent/5 rounded-lg">
              <div className="text-sm font-medium text-gray-900">HS Code</div>
              <div className="text-2xl font-bold tracking-tight text-gray-900">
                {formatHSCode(result.code, parseInt(hsDigits))}
              </div>
              <div className="text-sm font-medium text-gray-900 mt-1">
                Confidence: {result.confidence}%
              </div>
            </div>

            {/* Classification Analysis */}
            <div className="border-t border-gray-300 pt-4">
              <div className="text-sm font-medium text-gray-900 mb-2">
                Classification Analysis
              </div>
              <div className="space-y-3">
                <div>
                  <div className="text-sm font-medium text-gray-900">
                    Primary Classification
                  </div>
                  <div className="text-sm text-gray-700">
                    {result.description}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-900">
                    Reasoning
                  </div>
                  <div className="text-sm text-gray-700">
                    {result.reasoning}
                  </div>
                </div>
              </div>
            </div>

            {/* Product Details */}
            <div className="border-t border-gray-300 pt-4">
              <div className="text-sm font-medium text-gray-900 mb-2">
                Product Details
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="text-sm font-medium text-gray-900">Type</div>
                  <div className="text-sm">
                    {result.classification_notes?.product_type || 'N/A'}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-900">Stage</div>
                  <div className="text-sm">
                    {result.classification_notes?.manufacture_stage || 'N/A'}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-900">
                    Key Materials
                  </div>
                  <div className="text-sm">
                    {result.classification_notes?.key_materials || 'N/A'}
                  </div>
                </div>
              </div>
            </div>

            {/* Trade Requirements */}
            <div className="border-t border-gray-300 pt-4">
              <div className="text-sm font-medium text-gray-900 mb-2">
                Trade Requirements
              </div>
              {Object.entries(result.trade_considerations).map(
                ([key, value]) => (
                  <div key={key} className="mb-4">
                    <div className="text-sm font-medium text-gray-900">
                      {key
                        .split('_')
                        .map(
                          word => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(' ')}
                    </div>
                    <div className="text-sm whitespace-pre-line">
                      {formatTradeConsiderationValue(key, value) || 'N/A'}
                    </div>
                  </div>
                )
              )}
            </div>

            {/* Classification Hierarchy */}
            <div className="border-t border-gray-300 pt-4">
              <div className="text-sm font-medium text-gray-900 mb-2">
                Classification Hierarchy
              </div>
              <div className="space-y-2">
                <div>
                  <div className="text-sm font-medium text-gray-900 capitalize">
                    Chapter
                  </div>
                  <div className="text-sm">
                    {result.hierarchy.chapter.code} -{' '}
                    {result.hierarchy.chapter.description}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-900 capitalize">
                    Heading
                  </div>
                  <div className="text-sm">
                    {result.hierarchy.heading.code} -{' '}
                    {result.hierarchy.heading.description}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-900 capitalize">
                    Subheading
                  </div>
                  <div className="text-sm">
                    {result.code.substring(0, 6)} - {result.description}
                  </div>
                </div>
              </div>
            </div>

            {/* Alternative Codes */}
            {result.alternatives.length > 0 && (
              <div className="border-t border-gray-300 pt-4">
                <div className="text-sm font-medium text-gray-900 mb-2">
                  Alternative Classifications
                </div>
                <div className="space-y-2">
                  {result.alternatives.map((alt, idx) => (
                    <div key={idx} className="text-sm">
                      {formatHSCode(alt.code, parseInt(hsDigits))} -{' '}
                      {alt.description}
                      <div className="text-xs text-gray-700 mt-1">
                        Confidence: {alt.confidence}% - {alt.differentiators}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
