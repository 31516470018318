import { useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@/components/ui/card';
import { Loader2 } from 'lucide-react';
import { AddUserDialog } from '@/components/users/AddUserDialog';
import { UsersTable } from '@/components/users/UsersTable';
import { useUserManagement } from '@/hooks/useUserManagement';

export default function Users() {
  const {
    users,
    isLoading,
    fetchUsers,
    addUser,
    updateUser,
    updatePassword,
    deleteUser,
  } = useUserManagement();

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold">Users</h1>
          <p className="text-muted-foreground">
            Manage user access and permissions
          </p>
        </div>
        <AddUserDialog onAddUser={addUser} />
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Users</CardTitle>
          <CardDescription>
            View and manage all users in the system
          </CardDescription>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="flex justify-center py-8">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <UsersTable
              users={users}
              onDeleteUser={deleteUser}
              onUpdateUser={updateUser}
              onUpdatePassword={updatePassword}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
}
