import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../../../hooks/useApi';
import type {
  Template,
  TemplatesResponse,
  UploadTemplateRequest,
  UploadTemplateResponse,
  DeleteTemplateResponse,
} from '../types/templates';

const TEMPLATES_KEY = ['templates'] as const;

export function useTemplates() {
  const api = useApi();

  return useQuery({
    queryKey: TEMPLATES_KEY,
    queryFn: async () => {
      const response = await api.get<TemplatesResponse>('/settings/templates');
      return response.templates;
    },
  });
}

export function useUploadTemplate() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ file, type }: UploadTemplateRequest) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', type);

      const response = await api.post<UploadTemplateResponse>(
        '/settings/templates/upload',
        formData
      );
      return response.template;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: TEMPLATES_KEY });
    },
  });
}

export function useDeleteTemplate() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      const response = await api.delete<DeleteTemplateResponse>(
        `/settings/templates/${id}`
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: TEMPLATES_KEY });
    },
  });
}
