import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useTemplates } from '@/lib/api';
import { getApiUrl } from '@/lib/config';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useToast } from '@/hooks/use-toast';
import {
  FileCheck,
  MessageSquare,
  Upload,
  AlertTriangle,
  X,
} from 'lucide-react';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { Input } from '@/components/ui/input';

const docCheckSchema = z.object({
  template: z.string().min(1, 'Please select a template'),
});

interface Template {
  id: number;
  name: string;
  type: string;
}

interface Discrepancy {
  type: 'missing' | 'mismatch' | 'extra';
  field: string;
  expected?: string;
  found?: string;
  description: string;
}

interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
  timestamp: Date;
}

interface CompareResponse {
  discrepancies: Discrepancy[];
  success: boolean;
  message?: string;
}

interface ChatResponse {
  message: string;
  success: boolean;
}

interface TemplateResponse {
  templates: Template[];
  success: boolean;
}

export default function DocCheck() {
  const [baseFile, setBaseFile] = useState<File | null>(null);
  const [compareFile, setCompareFile] = useState<File | null>(null);
  const [templateList, setTemplateList] = useState<Template[]>([]);
  const [discrepancies, setDiscrepancies] = useState<Discrepancy[]>([]);
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const { toast } = useToast();

  const form = useForm<z.infer<typeof docCheckSchema>>({
    resolver: zodResolver(docCheckSchema),
  });

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await fetch(getApiUrl('document-templates'));
      if (!response.ok) {
        throw new Error('Failed to fetch templates');
      }
      const data = (await response.json()) as TemplateResponse;
      if (data.success && Array.isArray(data.templates)) {
        setTemplateList(data.templates);
      } else {
        throw new Error('Invalid template data received');
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch templates',
        variant: 'destructive',
      });
    }
  };

  const handleBaseFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setBaseFile(file);
    }
  };

  const handleCompareFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setCompareFile(file);
    }
  };

  const handleCompare = async (values: z.infer<typeof docCheckSchema>) => {
    if (!baseFile || !compareFile) {
      toast({
        title: 'Missing files',
        description: 'Please select both base and comparison files',
        variant: 'destructive',
      });
      return;
    }

    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('baseFile', baseFile);
      formData.append('compareFile', compareFile);
      formData.append('templateId', values.template);

      const response = await fetch(getApiUrl('document-check/compare'), {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to compare documents');
      }

      const result = (await response.json()) as CompareResponse;

      if (!result.success) {
        throw new Error(result.message || 'Failed to compare documents');
      }

      setDiscrepancies(result.discrepancies);

      // Add initial AI analysis to chat
      const initialAnalysis: ChatMessage = {
        role: 'assistant',
        content:
          "I've analyzed the documents. Here are the key differences I found:\n\n" +
          result.discrepancies
            .map(
              (d: Discrepancy) =>
                `• ${
                  d.type === 'missing'
                    ? 'Missing'
                    : d.type === 'mismatch'
                      ? 'Mismatch in'
                      : 'Extra'
                } ${d.field}` + (d.description ? `: ${d.description}` : '')
            )
            .join('\n'),
        timestamp: new Date(),
      };
      setChatMessages([initialAnalysis]);
    } catch (error) {
      console.error('Error comparing documents:', error);
      toast({
        title: 'Error',
        description:
          error instanceof Error
            ? error.message
            : 'Failed to compare documents',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (!messageInput.trim()) return;

    const userMessage: ChatMessage = {
      role: 'user',
      content: messageInput,
      timestamp: new Date(),
    };

    setChatMessages(prev => [...prev, userMessage]);
    setMessageInput('');

    try {
      const response = await fetch(getApiUrl('document-check/chat'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: messageInput,
          discrepancies,
          history: chatMessages,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to get AI response');
      }

      const aiResponse = (await response.json()) as ChatResponse;

      if (!aiResponse.success) {
        throw new Error('Failed to get AI response');
      }

      const assistantMessage: ChatMessage = {
        role: 'assistant',
        content: aiResponse.message,
        timestamp: new Date(),
      };

      setChatMessages(prev => [...prev, assistantMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to get AI response',
        variant: 'destructive',
      });
    }
  };

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Document Check</h1>
        <Sheet open={isChatOpen} onOpenChange={setIsChatOpen}>
          <SheetTrigger asChild>
            <Button variant="outline">
              <MessageSquare className="mr-2 h-4 w-4" />
              Chat
            </Button>
          </SheetTrigger>
          <SheetContent className="w-[400px] sm:w-[540px]">
            <SheetHeader>
              <SheetTitle>Document Analysis Chat</SheetTitle>
            </SheetHeader>
            <div className="flex flex-col h-full">
              <ScrollArea className="flex-1 pr-4">
                <div className="space-y-4 mt-4">
                  {chatMessages.map((message, i) => (
                    <div
                      key={i}
                      className={`flex ${
                        message.role === 'assistant'
                          ? 'justify-start'
                          : 'justify-end'
                      }`}
                    >
                      <div
                        className={`max-w-[80%] rounded-lg p-3 ${
                          message.role === 'assistant'
                            ? 'bg-secondary'
                            : 'bg-primary text-primary-foreground'
                        }`}
                      >
                        <p className="whitespace-pre-line">{message.content}</p>
                        <span className="text-xs opacity-70">
                          {message.timestamp.toLocaleTimeString()}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollArea>
              <div className="flex gap-2 mt-4">
                <Input
                  value={messageInput}
                  onChange={e => setMessageInput(e.target.value)}
                  placeholder="Ask about the document comparison..."
                  onKeyDown={e => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                />
                <Button onClick={handleSendMessage}>Send</Button>
              </div>
            </div>
          </SheetContent>
        </Sheet>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        <Card className="w-full">
          <CardHeader>
            <CardTitle>Base Template</CardTitle>
          </CardHeader>
          <CardContent>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(handleCompare)}
                className="space-y-4"
              >
                <FormField
                  control={form.control}
                  name="template"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Template</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a template" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {!templateList.length ? (
                            <SelectItem value="loading" disabled>
                              Loading templates...
                            </SelectItem>
                          ) : (
                            templateList.map((template: Template) => (
                              <SelectItem
                                key={template.id}
                                value={template.id.toString()}
                              >
                                {template.name}
                              </SelectItem>
                            ))
                          )}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />

                <div className="border-2 border-dashed rounded-lg p-6 text-center">
                  <input
                    type="file"
                    id="baseFile"
                    className="hidden"
                    accept=".pdf,.docx,.xlsx"
                    onChange={handleBaseFileChange}
                  />
                  <label
                    htmlFor="baseFile"
                    className="cursor-pointer flex flex-col items-center gap-2"
                  >
                    <Upload size={24} />
                    <span className="font-medium">
                      {baseFile ? baseFile.name : 'Upload base document'}
                    </span>
                    <span className="text-sm text-muted-foreground">
                      PDF, Word, or Excel files
                    </span>
                  </label>
                </div>
              </form>
            </Form>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Compare Document</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="border-2 border-dashed rounded-lg p-6 text-center">
              <input
                type="file"
                id="compareFile"
                className="hidden"
                accept=".pdf,.docx,.xlsx"
                onChange={handleCompareFileChange}
              />
              <label
                htmlFor="compareFile"
                className="cursor-pointer flex flex-col items-center gap-2"
              >
                <Upload size={24} />
                <span className="font-medium">
                  {compareFile
                    ? compareFile.name
                    : 'Upload document to compare'}
                </span>
                <span className="text-sm text-muted-foreground">
                  PDF, Word, or Excel files
                </span>
              </label>
            </div>

            <Button
              onClick={form.handleSubmit(handleCompare)}
              className="w-full mt-4"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <FileCheck className="mr-2 h-4 w-4 animate-spin" />
                  Comparing...
                </>
              ) : (
                <>
                  <FileCheck className="mr-2 h-4 w-4" />
                  Compare Documents
                </>
              )}
            </Button>
          </CardContent>
        </Card>
      </div>

      {discrepancies.length > 0 && (
        <Card>
          <CardHeader>
            <CardTitle>Discrepancies Found</CardTitle>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[300px]">
              <div className="space-y-4">
                {discrepancies.map((discrepancy, index) => (
                  <div
                    key={index}
                    className={`p-4 rounded-lg border ${
                      discrepancy.type === 'missing'
                        ? 'bg-red-50 border-red-200'
                        : discrepancy.type === 'mismatch'
                          ? 'bg-yellow-50 border-yellow-200'
                          : 'bg-blue-50 border-blue-200'
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      <AlertTriangle
                        className={`h-5 w-5 ${
                          discrepancy.type === 'missing'
                            ? 'text-red-500'
                            : discrepancy.type === 'mismatch'
                              ? 'text-yellow-500'
                              : 'text-blue-500'
                        }`}
                      />
                      <h3 className="font-medium">
                        {discrepancy.type === 'missing'
                          ? 'Missing Field'
                          : discrepancy.type === 'mismatch'
                            ? 'Field Mismatch'
                            : 'Extra Field'}
                      </h3>
                    </div>
                    <div className="mt-2 space-y-1">
                      <p className="text-sm">Field: {discrepancy.field}</p>
                      {discrepancy.expected && (
                        <p className="text-sm">
                          Expected: {discrepancy.expected}
                        </p>
                      )}
                      {discrepancy.found && (
                        <p className="text-sm">Found: {discrepancy.found}</p>
                      )}
                      <p className="text-sm text-gray-600">
                        {discrepancy.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      )}
    </div>
  );
}
