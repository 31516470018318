import { z } from 'zod';

export const loginSchema = z.object({
  email: z
    .string()
    .min(1, 'Email is required')
    .email('Please enter a valid email address')
    .transform(val => val.toLowerCase()),
  password: z
    .string()
    .min(1, 'Password is required')
    .min(6, 'Password must be at least 6 characters'),
});

export type LoginFormValues = z.infer<typeof loginSchema>;

export interface AuthResponse {
  success: boolean;
  token?: string;
  user?: {
    id: number;
    email: string;
    name: string;
    role: string;
  };
  error?: string;
}

export const AUTH_ERRORS = {
  INVALID_CREDENTIALS: 'Invalid email or password',
  MISSING_CREDENTIALS: 'Please enter both email and password',
  SERVER_ERROR: 'An error occurred during login. Please try again later.',
  SESSION_EXPIRED: 'Your session has expired. Please log in again.',
  NETWORK_ERROR:
    'Unable to connect to the server. Please check your connection.',
} as const;
