import { useState } from 'react';
import { useToast } from '@/components/ui/use-toast';
import { fetchApi } from '@/lib/api/utils/fetch';

interface Tenant {
  id: string;
  name: string;
  status: 'active' | 'disabled';
  email: string;
  contactName: string;
  phone: string;
  maxUsers: number;
  currentUsers: number;
  domain: string;
  isPrimary: boolean;
  domainStatus: 'active' | 'inactive';
}

interface TenantInput {
  name: string;
  status: 'active' | 'disabled';
  email: string;
  contactName: string;
  phone: string;
  maxUsers: number;
  domain: string;
  isPrimary: boolean;
  domainStatus: 'active' | 'inactive';
}

export function useTenantManagement() {
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const fetchTenants = async () => {
    setIsLoading(true);
    try {
      const data = await fetchApi<Tenant[]>('tenants');
      setTenants(data);
    } catch (error) {
      console.error('Error fetching tenants:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch tenants',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const addTenant = async (tenantData: TenantInput): Promise<boolean> => {
    try {
      const newTenant = await fetchApi<Tenant>('tenants', {
        method: 'POST',
        body: JSON.stringify(tenantData),
      });
      setTenants(prev => [...prev, newTenant]);
      toast({
        title: 'Success',
        description: 'Tenant added successfully',
      });
      return true;
    } catch (error) {
      console.error('Error adding tenant:', error);
      toast({
        title: 'Error',
        description: 'Failed to add tenant',
        variant: 'destructive',
      });
      return false;
    }
  };

  const updateTenant = async (
    id: string,
    data: Partial<Tenant>
  ): Promise<boolean> => {
    try {
      const updatedTenant = await fetchApi<Tenant>(`tenants/${id}`, {
        method: 'PATCH',
        body: JSON.stringify(data),
      });
      setTenants(prev =>
        prev.map(tenant =>
          tenant.id === id ? { ...tenant, ...updatedTenant } : tenant
        )
      );
      toast({
        title: 'Success',
        description: 'Tenant updated successfully',
      });
      return true;
    } catch (error) {
      console.error('Error updating tenant:', error);
      toast({
        title: 'Error',
        description: 'Failed to update tenant',
        variant: 'destructive',
      });
      return false;
    }
  };

  const deleteTenant = async (id: string): Promise<boolean> => {
    try {
      await fetchApi(`tenants/${id}`, {
        method: 'DELETE',
      });
      setTenants(prev => prev.filter(tenant => tenant.id !== id));
      toast({
        title: 'Success',
        description: 'Tenant deleted successfully',
      });
      return true;
    } catch (error) {
      console.error('Error deleting tenant:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete tenant',
        variant: 'destructive',
      });
      return false;
    }
  };

  const toggleTenantStatus = async (id: string): Promise<boolean> => {
    const tenant = tenants.find(t => t.id === id);
    if (!tenant) return false;

    const newStatus = tenant.status === 'active' ? 'disabled' : 'active';
    return updateTenant(id, { status: newStatus });
  };

  return {
    tenants,
    isLoading,
    fetchTenants,
    addTenant,
    updateTenant,
    deleteTenant,
    toggleTenantStatus,
  };
}
