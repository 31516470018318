import { User } from '@/types/user';

interface AuthState {
  token: string | null;
  user: User | null;
  isAuthenticated: boolean;
}

const AUTH_STORAGE_KEY = 'token';
const USER_STORAGE_KEY = 'user';

const defaultState: AuthState = {
  token: null,
  user: null,
  isAuthenticated: false,
};

export function getStoredAuthState(): AuthState {
  try {
    const token = localStorage.getItem(AUTH_STORAGE_KEY);
    const userJson = localStorage.getItem(USER_STORAGE_KEY);

    if (!token) {
      return defaultState;
    }

    let user: User | null = null;
    if (userJson) {
      try {
        user = JSON.parse(userJson);
      } catch (error) {
        console.error('Failed to parse stored user:', error);
      }
    }

    return {
      token,
      user,
      isAuthenticated: true,
    };
  } catch (error) {
    console.error('Failed to get stored auth state:', error);
    return defaultState;
  }
}

export function setStoredAuthState(state: AuthState): void {
  try {
    if (state.token) {
      localStorage.setItem(AUTH_STORAGE_KEY, state.token);
      if (state.user) {
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(state.user));
      }
    } else {
      clearStoredAuthState();
    }
  } catch (error) {
    console.error('Failed to store auth state:', error);
  }
}

export function clearStoredAuthState(): void {
  try {
    localStorage.removeItem(AUTH_STORAGE_KEY);
    localStorage.removeItem(USER_STORAGE_KEY);
  } catch (error) {
    console.error('Failed to clear auth state:', error);
  }
}

export function setAuthToken(token: string, user: User): void {
  if (!token) {
    console.error('Invalid token');
    return;
  }

  setStoredAuthState({
    token,
    user,
    isAuthenticated: true,
  });
}

export function getAuthToken(): string | null {
  return localStorage.getItem(AUTH_STORAGE_KEY);
}

export function getStoredUser(): User | null {
  try {
    const userJson = localStorage.getItem(USER_STORAGE_KEY);
    return userJson ? JSON.parse(userJson) : null;
  } catch (error) {
    console.error('Failed to get stored user:', error);
    return null;
  }
}

export function isAuthenticated(): boolean {
  return !!getAuthToken() && !!getStoredUser();
}
