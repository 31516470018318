import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { Menu, ChevronLeft, ChevronRight, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Sidebar as BaseSidebar, SidebarHeader } from '@/components/ui/sidebar';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { useSidebar } from '@/components/ui/sidebar';
import { SidebarMenuItems } from './SidebarMenu';

export default function Sidebar() {
  const { state, setOpen, isMobile, openMobile, setOpenMobile } = useSidebar();
  const isCollapsed = state === 'collapsed';

  const HeaderContent = () => (
    <div className="flex items-center justify-between">
      <Link
        to="/"
        className={cn(
          'flex items-center gap-3 hover:no-underline',
          isCollapsed && !isMobile && 'justify-center'
        )}
      >
        <img
          src="/logo.png"
          alt="AI Logistics Logo"
          className="size-8 object-contain"
        />
        {(!isCollapsed || isMobile) && (
          <span className="font-semibold text-lg truncate">AI Logistics</span>
        )}
      </Link>
      {!isMobile && (
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setOpen(!isCollapsed)}
          className={cn(
            'size-8 shrink-0',
            isCollapsed &&
              '-mr-4 absolute right-0 bg-background border rounded-full shadow-md hover:bg-accent'
          )}
        >
          {isCollapsed ? (
            <ChevronRight className="size-4" />
          ) : (
            <ChevronLeft className="size-4" />
          )}
        </Button>
      )}
      {isMobile && (
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setOpenMobile(false)}
          className="size-8 lg:hidden"
        >
          <X className="size-4" />
        </Button>
      )}
    </div>
  );

  return (
    <>
      {/* Desktop Sidebar */}
      {!isMobile && (
        <BaseSidebar collapsible="icon">
          <div className="flex flex-col h-full">
            <SidebarHeader
              className={cn(
                'flex flex-col gap-4 p-4',
                isCollapsed && 'items-center'
              )}
            >
              <HeaderContent />
            </SidebarHeader>
            <SidebarMenuItems />
          </div>
        </BaseSidebar>
      )}

      {/* Mobile Header */}
      {isMobile && (
        <header className="fixed top-0 inset-x-0 z-50 flex items-center justify-between h-16 p-4 bg-background border-b md:hidden">
          <Link to="/" className="flex items-center gap-3 hover:no-underline">
            <img
              src="/logo.png"
              alt="AI Logistics Logo"
              className="size-8 object-contain"
            />
            <span className="font-semibold text-lg">AI Logistics</span>
          </Link>
          <Sheet open={openMobile} onOpenChange={setOpenMobile}>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon" className="size-8">
                <Menu className="size-6" />
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="p-0 w-[var(--sidebar-width)]">
              <nav className="flex flex-col h-full">
                <SidebarHeader className="flex flex-col gap-4 p-4">
                  <HeaderContent />
                </SidebarHeader>
                <SidebarMenuItems />
              </nav>
            </SheetContent>
          </Sheet>
        </header>
      )}
    </>
  );
}
