export const PRODUCT_TYPE_OPTIONS = [
  { value: 'complete', label: 'Complete Product' },
  { value: 'part', label: 'Part' },
  { value: 'accessory', label: 'Accessory' },
] as const;

export const MANUFACTURE_STAGE_OPTIONS = [
  { value: 'finished', label: 'Finished' },
  { value: 'semi-finished', label: 'Semi-finished' },
  { value: 'raw', label: 'Raw Material' },
] as const;

export const HS_DIGIT_OPTIONS = [
  { value: '6', label: '6 digits' },
  { value: '8', label: '8 digits' },
  { value: '10', label: '10 digits' },
  { value: '12', label: '12 digits' },
  { value: '14', label: '14 digits' },
] as const;

export const FEEDBACK_LABELS = {
  accuracy: {
    title: 'Classification Accuracy',
    description: 'How accurate is the suggested HS code?',
    min: 'Poor',
    max: 'Excellent',
  },
  codeSpecificity: {
    title: 'Code Specificity',
    description: 'How specific and appropriate is the code level?',
    min: 'Too Broad',
    max: 'Perfect Detail',
  },
  reasoningClarity: {
    title: 'Reasoning Clarity',
    description: 'How clear and helpful is the classification reasoning?',
    min: 'Unclear',
    max: 'Very Clear',
  },
  tradingRequirements: {
    title: 'Trading Requirements',
    description: 'How complete are the trade requirement details?',
    min: 'Incomplete',
    max: 'Complete',
  },
  alternativesQuality: {
    title: 'Alternatives Quality',
    description: 'How relevant are the alternative classifications?',
    min: 'Irrelevant',
    max: 'Very Relevant',
  },
} as const;
