import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Slider } from '@/components/ui/slider';
import { Textarea } from '@/components/ui/textarea';
import { FEEDBACK_LABELS } from '@/lib/api/constants/hs-lookup';
import type { FeedbackData } from '@/lib/api/types/hs-lookup';

interface FeedbackDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  feedbackData: FeedbackData;
  onSubmit: () => Promise<void>;
  updateFeedbackMetric: (
    metric: keyof Omit<FeedbackData, 'comments'>,
    value: number
  ) => void;
  updateComments: (comments: string) => void;
}

export function FeedbackDialog({
  open,
  onOpenChange,
  feedbackData,
  onSubmit,
  updateFeedbackMetric,
  updateComments,
}: FeedbackDialogProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Rate Classification Quality</DialogTitle>
          <DialogDescription>
            Help us improve our HS code classification by rating these aspects
            from 1 to 5
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="space-y-6">
            {(
              Object.keys(FEEDBACK_LABELS) as Array<
                keyof typeof FEEDBACK_LABELS
              >
            ).map(metric => (
              <div key={metric}>
                <label className="text-sm font-medium mb-2 block">
                  {FEEDBACK_LABELS[metric].title}
                  <span className="text-xs text-gray-500 ml-2">
                    {FEEDBACK_LABELS[metric].description}
                  </span>
                </label>
                <Slider
                  value={[feedbackData[metric]]}
                  min={1}
                  max={5}
                  step={1}
                  onValueChange={([value]) =>
                    updateFeedbackMetric(metric, value)
                  }
                  className="py-2"
                />
                <div className="flex justify-between text-xs text-gray-500">
                  <span>{FEEDBACK_LABELS[metric].min}</span>
                  <span>{FEEDBACK_LABELS[metric].max}</span>
                </div>
              </div>
            ))}

            <div>
              <label className="text-sm font-medium mb-2 block">
                Additional Comments
                <span className="text-xs text-gray-500 ml-2">Optional</span>
              </label>
              <Textarea
                value={feedbackData.comments}
                onChange={e => updateComments(e.target.value)}
                placeholder="Share any additional feedback or suggestions..."
                className="resize-none h-24"
              />
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={onSubmit}>Submit Feedback</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
