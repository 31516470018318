import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import type { ChatSettings } from '../types/chat-settings';

const CHAT_SETTINGS_KEY = 'chat-settings';

export function useChatSettings() {
  const api = useApi();

  return useQuery({
    queryKey: [CHAT_SETTINGS_KEY],
    queryFn: async () => {
      const response = await api.get<{
        success: boolean;
        settings: ChatSettings;
      }>('/settings/chat');
      if (!response.success) {
        throw new Error('Failed to fetch chat settings');
      }
      return response.settings;
    },
  });
}

export function useUpdateChatSettings() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (settings: ChatSettings) => {
      const response = await api.post<{
        success: boolean;
        settings: ChatSettings;
      }>('/settings/chat', settings);
      if (!response.success) {
        throw new Error('Failed to update chat settings');
      }
      return response.settings;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CHAT_SETTINGS_KEY] });
    },
  });
}

export function useClearChatHistory() {
  const api = useApi();

  return useMutation({
    mutationFn: async () => {
      const response = await api.delete<{ success: boolean }>(
        '/settings/chat/history'
      );
      if (!response.success) {
        throw new Error('Failed to clear chat history');
      }
      return response;
    },
  });
}
