import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@/components/ui/card';
import { Loader2 } from 'lucide-react';
import { AddUserDialog } from '@/components/users/AddUserDialog';
import { UsersTable } from '@/components/users/UsersTable';
import { useToast } from '@/components/ui/use-toast';
import config from '@/lib/config';
import { User, UserUpdateData } from '@/types/user';

export default function TenantUsers() {
  const { id: tenantId } = useParams<{ id: string }>();
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        `${config.apiUrl}/tenants/${tenantId}/users`,
        {
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to fetch users');
      }

      const data = await response.json();
      const transformedUsers: User[] = data.map((user: any) => ({
        ...user,
        role: user.is_superadmin ? 'superadmin' : 'user',
      }));
      setUsers(transformedUsers);
    } catch (error) {
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to fetch users',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddUser = async (userData: any): Promise<boolean> => {
    try {
      const response = await fetch(
        `${config.apiUrl}/tenants/${tenantId}/users`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(userData),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to add user');
      }

      await fetchUsers();
      toast({
        title: 'Success',
        description: 'User added successfully',
      });
      return true;
    } catch (error) {
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to add user',
        variant: 'destructive',
      });
      return false;
    }
  };

  const handleDeleteUser = async (id: string): Promise<boolean> => {
    try {
      const response = await fetch(`${config.apiUrl}/users/${id}`, {
        method: 'DELETE',
        credentials: 'include',
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to delete user');
      }

      await fetchUsers();
      toast({
        title: 'Success',
        description: 'User deleted successfully',
      });
      return true;
    } catch (error) {
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to delete user',
        variant: 'destructive',
      });
      return false;
    }
  };

  const handleUpdateUser = async (
    userData: UserUpdateData
  ): Promise<boolean> => {
    try {
      const response = await fetch(`${config.apiUrl}/users/${userData.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to update user');
      }

      await fetchUsers();
      toast({
        title: 'Success',
        description: 'User updated successfully',
      });
      return true;
    } catch (error) {
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to update user',
        variant: 'destructive',
      });
      return false;
    }
  };

  const handleUpdatePassword = async (
    id: string,
    password: string
  ): Promise<boolean> => {
    try {
      const response = await fetch(`${config.apiUrl}/users/${id}/password`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ password }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to update password');
      }

      toast({
        title: 'Success',
        description: 'Password updated successfully',
      });
      return true;
    } catch (error) {
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to update password',
        variant: 'destructive',
      });
      return false;
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [tenantId]);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold">Tenant Users</h1>
          <p className="text-muted-foreground">
            Manage users for this enterprise tenant
          </p>
        </div>
        <div className="space-x-4">
          <AddUserDialog onAddUser={handleAddUser} />
        </div>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Users</CardTitle>
          <CardDescription>
            View and manage all users in this tenant
          </CardDescription>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="flex justify-center py-8">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <UsersTable
              users={users}
              onDeleteUser={handleDeleteUser}
              onUpdateUser={handleUpdateUser}
              onUpdatePassword={handleUpdatePassword}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
}
