import { Link, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { LucideIcon } from 'lucide-react';
import {
  FileSpreadsheet,
  Search,
  Ship,
  FileCheck,
  Users,
  MessageSquare,
  Settings,
  LogOut,
  LayoutDashboard,
  Cog,
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/contexts/AuthContext';
import { hasPageAccess, PAGE_PERMISSIONS } from '@/lib/permissions';
import { useSidebar } from '@/components/ui/sidebar';
import {
  SidebarContent,
  SidebarFooter,
  SidebarMenu as SidebarMenuComponent,
} from '@/components/ui/sidebar';

interface MenuItem {
  icon: LucideIcon;
  label: string;
  href: string;
  permission: keyof typeof PAGE_PERMISSIONS;
  requireSuperAdmin?: boolean;
}

const menuItems: MenuItem[] = [
  {
    icon: LayoutDashboard,
    label: 'Dashboard',
    href: '/',
    permission: 'dashboard',
  },
  {
    icon: FileSpreadsheet,
    label: 'Convert to Excel',
    href: '/convert',
    permission: 'convert',
  },
  {
    icon: Search,
    label: 'HS Lookup',
    href: '/hs-lookup',
    permission: 'hsLookup',
  },
  {
    icon: Ship,
    label: 'FCL Search',
    href: '/fcl-search',
    permission: 'fclSearch',
  },
  {
    icon: FileCheck,
    label: 'Document Check',
    href: '/doc-check',
    permission: 'docCheck',
  },
  { icon: MessageSquare, label: 'Chat', href: '/chat', permission: 'chat' },
  { icon: Users, label: 'Users', href: '/users', permission: 'users' },
  {
    icon: Settings,
    label: 'Settings',
    href: '/settings',
    permission: 'settings',
  },
  {
    icon: Cog,
    label: 'Tenant Management',
    href: '/tenants',
    permission: 'superadmin',
  },
];

export function SidebarMenuItems() {
  const location = useLocation();
  const { logout, user } = useAuth();
  const { state, isMobile, setOpenMobile } = useSidebar();
  const isCollapsed = state === 'collapsed';

  return (
    <>
      <SidebarContent className={cn('px-2', isCollapsed && 'items-center')}>
        <SidebarMenuComponent>
          {menuItems.map(item => {
            const Icon = item.icon;
            const isActive = location.pathname === item.href;

            if (!hasPageAccess(user, item.permission)) {
              return null;
            }

            return (
              <Link
                key={item.href}
                to={item.href}
                className="no-underline w-full"
                onClick={() => isMobile && setOpenMobile(false)}
              >
                <Button
                  variant={isActive ? 'secondary' : 'ghost'}
                  className={cn(
                    'w-full h-9 hover-accent-bg',
                    isCollapsed ? 'justify-center px-0' : 'justify-start gap-2'
                  )}
                >
                  <Icon className="h-4 w-4" />
                  {(!isCollapsed || isMobile) && <span>{item.label}</span>}
                </Button>
              </Link>
            );
          })}
        </SidebarMenuComponent>
      </SidebarContent>

      <SidebarFooter className={cn('px-2', isCollapsed && 'items-center')}>
        <SidebarMenuComponent>
          <Button
            variant="ghost"
            className={cn(
              'w-full h-9 hover-accent-bg',
              isCollapsed ? 'justify-center px-0' : 'justify-start gap-2'
            )}
            onClick={() => {
              logout();
              if (isMobile) setOpenMobile(false);
            }}
          >
            <LogOut className="h-4 w-4" />
            {(!isCollapsed || isMobile) && <span>Logout</span>}
          </Button>
        </SidebarMenuComponent>
      </SidebarFooter>
    </>
  );
}
