interface Config {
  apiUrl: string;
  baseUrl: string;
  environment: string;
}

const getEnvironmentConfig = (): Config => {
  // Check if running in Cloudflare Pages preview
  const isPreview =
    typeof window !== 'undefined' &&
    window.location.hostname.includes('.pages.dev');
  const isDevelopment = import.meta.env.MODE === 'development';
  const isProduction = import.meta.env.PROD;

  if (isPreview) {
    return {
      apiUrl: 'https://freight-forwarding-system-dev.opdeeppc.workers.dev',
      baseUrl: window.location.origin,
      environment: 'preview',
    };
  }

  if (isDevelopment) {
    return {
      apiUrl: 'http://127.0.0.1:8787',
      baseUrl: 'http://localhost:5173',
      environment: 'development',
    };
  }

  // Production
  return {
    apiUrl: 'https://api.shipmate.fpsaus.com.au',
    baseUrl: 'https://shipmate.fpsaus.com.au',
    environment: 'production',
  };
};

const config = getEnvironmentConfig();

export default config;

// Get the API URL based on environment
export const getApiUrl = (path?: string) => {
  const { apiUrl } = config;

  // Normalize path and ensure no double slashes
  const normalizedPath = path ? `/${path}`.replace(/\/+/g, '/') : '';

  // Add /api prefix for non-production environments
  const shouldPrefixApi = !config.environment.includes('production');
  const prefixedPath = shouldPrefixApi
    ? `/api${normalizedPath}`
    : normalizedPath;

  const finalUrl = `${apiUrl}${prefixedPath}`;

  console.log('getApiUrl:', {
    input: { path, hostname: window.location.hostname },
    environment: {
      mode: config.environment,
      isProd: import.meta.env.PROD,
    },
    construction: {
      apiUrl,
      shouldPrefixApi,
      normalizedPath,
      prefixedPath,
      finalUrl,
    },
  });

  return finalUrl;
};
