export type Provider = 'openai' | 'anthropic' | 'perplexity';

export interface AIModel {
  id: string;
  provider: Provider;
  friendly_name: string;
  model_name: string;
  created_at: string;
}

export interface FeatureModelSetting {
  id: string;
  feature_name: string;
  model_id: string;
  provider: string;
  model_name: string;
  friendly_name: string;
}

export interface NewModel {
  provider: Provider;
  friendly_name: string;
  model_name: string;
}

export const PROVIDER_NAMES = {
  openai: 'OpenAI',
  anthropic: 'Claude',
  perplexity: 'Perplexity',
} as const;

export const PROVIDER_ENV_KEYS = {
  openai: 'OPENAI_API',
  anthropic: 'ANTHROPIC_API',
  perplexity: 'PERPLEXITY_API',
} as const;

export const MODEL_FEATURES = {
  'hs-lookup-detailed': 'HS Code Lookup (Detailed)',
  'hs-lookup-simplified': 'HS Code Lookup (Simplified)',
  chat: 'Chat',
  'document-check': 'Document Check',
  'convert-to-excel': 'Convert to Excel',
  'fcl-rate': 'FCL Rate',
} as const;
