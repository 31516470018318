import { useQuery } from '@tanstack/react-query';
import { fetchApi } from '../utils/fetch';

interface DashboardStats {
  totalShipments: number;
  activeShipments: number;
  completedShipments: number;
  pendingShipments: number;
}

interface DashboardStatsResponse {
  success: boolean;
  stats: DashboardStats;
}

export function useDashboardStats() {
  return useQuery({
    queryKey: ['dashboard', 'stats'],
    queryFn: async () => {
      const response =
        await fetchApi<DashboardStatsResponse>('dashboard/stats');
      return response.stats;
    },
    refetchInterval: 30000, // Refetch every 30 seconds
  });
}
