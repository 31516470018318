import { getApiUrl } from '../../config';
import { getAuthToken, clearStoredAuthState } from '../../auth-store';
import { ApiResponse } from '../types/common';

interface FetchOptions extends RequestInit {
  skipAuth?: boolean;
}

export async function fetchApi<T>(
  endpoint: string,
  options: FetchOptions = {}
): Promise<T> {
  const { skipAuth, ...fetchOptions } = options;
  const token = !skipAuth && getAuthToken();

  // Log token state
  console.log('Auth state:', {
    hasToken: !!token,
    tokenPrefix: token ? token.substring(0, 20) + '...' : null,
  });

  const headers = new Headers(options.headers);
  headers.set('Content-Type', 'application/json');

  // Only add Authorization header if we have a token and it's not a login request
  if (token && !endpoint.includes('/auth/login')) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  const normalizedEndpoint = endpoint.startsWith('/')
    ? endpoint.slice(1)
    : endpoint;

  const url = `${getApiUrl()}/${normalizedEndpoint}`;

  // Log the exact request that will be made
  console.log('🚀 Making API request:', {
    url,
    method: fetchOptions.method || 'GET',
    headers: Object.fromEntries(headers.entries()),
    environment: {
      hostname: window.location.hostname,
      mode: import.meta.env.MODE,
      isProd: import.meta.env.PROD,
      origin: window.location.origin,
    },
  });

  const response = await fetch(url, {
    ...fetchOptions,
    headers,
    credentials: 'include', // Always include credentials for cookie support
  });

  // Log the exact response received
  console.log('📥 API Response:', {
    status: response.status,
    statusText: response.statusText,
    url: response.url, // This will show if there was a redirect
    headers: Object.fromEntries(response.headers.entries()),
    contentType: response.headers.get('content-type'),
  });

  const contentType = response.headers.get('content-type');
  let data;

  try {
    if (contentType?.includes('application/json')) {
      const text = await response.text();
      console.log('Response text:', text);
      data = text ? JSON.parse(text) : null;
    } else {
      const text = await response.text();
      console.error('❌ Non-JSON response:', {
        contentType,
        text: text.substring(0, 500) + (text.length > 500 ? '...' : ''),
        requestUrl: url,
        responseUrl: response.url,
      });
      throw new Error(`Unexpected content type: ${contentType}`);
    }
  } catch (error) {
    console.error('❌ Response parsing error:', {
      error,
      contentType,
      status: response.status,
      statusText: response.statusText,
      requestUrl: url,
      responseUrl: response.url,
    });
    throw new Error('Failed to parse response');
  }

  if (!response.ok) {
    if (response.status === 401) {
      clearStoredAuthState();
      window.location.href = '/login';
    }
    throw new Error(
      typeof data === 'object' && data?.error
        ? data.error
        : 'API request failed'
    );
  }

  return data;
}

export async function uploadFile<T>(
  endpoint: string,
  file: File | FormData,
  options: FetchOptions = {}
): Promise<T> {
  const formData = file instanceof FormData ? file : new FormData();
  if (file instanceof File) {
    formData.append('file', file);
  }

  const token = !options.skipAuth && getAuthToken();
  const headers = new Headers(options.headers);

  // Only add Authorization header if we have a token
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  const normalizedEndpoint = endpoint.startsWith('/')
    ? endpoint.slice(1)
    : endpoint;

  const response = await fetch(`${getApiUrl()}/${normalizedEndpoint}`, {
    method: 'POST',
    body: formData,
    ...options,
    headers,
    credentials: 'include', // Always include credentials for cookie support
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || `Failed to upload to ${endpoint}`);
  }

  return response.json();
}

export async function downloadFile(
  endpoint: string,
  options: FetchOptions = {}
): Promise<{ blob: Blob; filename: string }> {
  const token = !options.skipAuth && getAuthToken();
  const headers = new Headers(options.headers);

  // Only add Authorization header if we have a token
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  // Set Accept header for Excel files
  if (endpoint.includes('/hs-lookup/bulk/download/')) {
    headers.set('Accept', 'application/vnd.ms-excel');
  }

  const normalizedEndpoint = endpoint.startsWith('/')
    ? endpoint.slice(1)
    : endpoint;
  const url = `${getApiUrl()}/${normalizedEndpoint}`;

  const response = await fetch(url, {
    ...options,
    headers,
    credentials: 'include', // Always include credentials for cookie support
  });

  if (!response.ok) {
    throw new Error(`Failed to download from ${endpoint}`);
  }

  // Get filename from Content-Disposition header
  const contentDisposition = response.headers.get('Content-Disposition');
  let filename = 'downloaded-file';

  if (contentDisposition) {
    // Try UTF-8 encoded filename first
    const utf8Match = /filename\*=UTF-8''([^;]+)/.exec(contentDisposition);
    if (utf8Match && utf8Match[1]) {
      filename = decodeURIComponent(utf8Match[1]);
    } else {
      // Fallback to regular filename
      const asciiMatch = /filename="?([^"]*)"?/.exec(contentDisposition);
      if (asciiMatch && asciiMatch[1]) {
        filename = asciiMatch[1].replace(/['"]/g, '');
      }
    }
  }

  // Get the blob with the correct type for Excel files
  const contentType = response.headers.get('Content-Type');
  const blob = await response.blob();
  const typedBlob = new Blob([blob], {
    type: contentType || 'application/vnd.ms-excel',
  });

  return { blob: typedBlob, filename };
}
