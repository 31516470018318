import { getApiUrl } from '@/lib/config';
import type {
  HSLookupFormValues,
  HSLookupResponse,
  RecentBulkUpload,
} from '@/lib/api/types/hs-lookup';

export const hsLookupService = {
  search: async (values: HSLookupFormValues): Promise<HSLookupResponse> => {
    const response = await fetch(`${getApiUrl()}/hs-lookup/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        product_overview: values.product_overview || undefined,
        product_function: values.product_function || undefined,
        product_type: values.product_type || undefined,
        manufacture_stage: values.manufacture_stage || undefined,
        materials: values.materials || undefined,
        dimensions: values.dimensions || undefined,
        industry: values.industry || undefined,
        origin_country: values.origin_country || undefined,
        target_country: values.target_country || undefined,
        hsDigits: parseInt(values.hs_digits || '12'),
      }),
    });

    return response.json();
  },

  submitFeedback: async (payload: any) => {
    const response = await fetch(getApiUrl('hs-lookup/feedback'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(payload),
    });

    return response.json();
  },

  uploadBulk: async (file: File, hsDigits: string) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('hs_digits', hsDigits);

    const response = await fetch(`${getApiUrl()}/hs-lookup/bulk`, {
      method: 'POST',
      body: formData,
    });

    return response.json();
  },

  getRecentBulkUploads: async (): Promise<{ uploads: RecentBulkUpload[] }> => {
    const response = await fetch(`${getApiUrl()}/hs-lookup/recent-bulk`);
    if (!response.ok) {
      throw new Error('Failed to fetch recent bulk uploads');
    }
    return response.json();
  },
};
