import { useState, useCallback } from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';
import { Badge } from '@/components/ui/badge';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Upload, FileText, Trash2 } from 'lucide-react';
import { cn } from '@/lib/utils';
import {
  useDocumentTemplates,
  useUploadDocumentTemplate,
  useDeleteDocumentTemplate,
} from '@/lib/api/services/document-check';

export default function DocumentCheckSettings() {
  const { toast } = useToast();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const { data: templates = [], isLoading } = useDocumentTemplates();
  const uploadTemplateMutation = useUploadDocumentTemplate();
  const deleteTemplateMutation = useDeleteDocumentTemplate();

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
    }
  }, []);

  const handleFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        setSelectedFile(file);
      }
    },
    []
  );

  const handleUpload = async () => {
    if (!selectedFile) return;

    try {
      await uploadTemplateMutation.mutateAsync(selectedFile);
      setSelectedFile(null);
      toast({
        title: 'Success',
        description: 'Template uploaded successfully',
      });
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to upload template',
      });
    }
  };

  const handleDeleteTemplate = async (templateId: number) => {
    try {
      await deleteTemplateMutation.mutateAsync(templateId);
      toast({
        title: 'Success',
        description: 'Template deleted successfully',
      });
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to delete template',
      });
    }
  };

  return (
    <div className="space-y-4">
      <Card>
        <CardContent className="pt-6">
          <div
            className={cn(
              'border-2 border-dashed rounded-lg p-6 text-center cursor-pointer transition-colors',
              isDragging
                ? 'border-primary bg-primary/10'
                : 'border-muted-foreground/25'
            )}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => document.getElementById('template-upload')?.click()}
          >
            <input
              type="file"
              id="template-upload"
              className="hidden"
              onChange={handleFileSelect}
              accept=".xlsx,.xls"
            />
            <Upload className="w-8 h-8 mx-auto mb-2 text-muted-foreground" />
            <p className="text-sm text-muted-foreground mb-1">
              Drag and drop your template here, or click to select
            </p>
            <p className="text-xs text-muted-foreground">
              Supports Excel files (.xlsx, .xls)
            </p>
          </div>

          {selectedFile && (
            <div className="mt-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <FileText className="w-4 h-4 text-muted-foreground" />
                  <span className="text-sm">{selectedFile.name}</span>
                </div>
                <Button
                  onClick={handleUpload}
                  disabled={uploadTemplateMutation.isLoading}
                >
                  {uploadTemplateMutation.isLoading
                    ? 'Uploading...'
                    : 'Upload Template'}
                </Button>
              </div>
            </div>
          )}
        </CardContent>
      </Card>

      <Card>
        <CardContent className="pt-6">
          <h3 className="text-lg font-semibold mb-4">Uploaded Templates</h3>
          <ScrollArea className="h-[300px]">
            {isLoading ? (
              <div className="text-center py-4">Loading templates...</div>
            ) : (
              <div className="space-y-4">
                {templates?.map(template => (
                  <div
                    key={template.id}
                    className="flex items-center justify-between p-3 bg-muted/50 rounded-lg"
                  >
                    <div className="flex items-center space-x-3">
                      <FileText className="w-4 h-4 text-muted-foreground" />
                      <div>
                        <p className="font-medium">{template.name}</p>
                        <Badge variant="secondary">{template.type}</Badge>
                      </div>
                    </div>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => handleDeleteTemplate(template.id)}
                      disabled={deleteTemplateMutation.isLoading}
                    >
                      <Trash2 className="w-4 h-4 text-destructive" />
                    </Button>
                  </div>
                ))}
                {(!templates || templates.length === 0) && (
                  <p className="text-sm text-muted-foreground text-center py-4">
                    No templates uploaded yet
                  </p>
                )}
              </div>
            )}
          </ScrollArea>
        </CardContent>
      </Card>
    </div>
  );
}
