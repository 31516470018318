import { useState } from 'react';
import { useToast } from '@/components/ui/use-toast';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Card } from '@/components/ui/card';
import { MessageSquare } from 'lucide-react';
import {
  useChatSettings,
  useUpdateChatSettings,
  useClearChatHistory,
} from '@/lib/api/services/chat-settings';
import { type AIModelType, AI_MODELS } from '@/lib/api/types/chat-settings';

export default function ChatSettings() {
  const { toast } = useToast();
  const { data: settings, isLoading } = useChatSettings();
  const updateSettingsMutation = useUpdateChatSettings();
  const clearHistoryMutation = useClearChatHistory();

  const [selectedModel, setSelectedModel] = useState<AIModelType>(
    (settings?.model || 'gpt-4') as AIModelType
  );
  const [temperature, setTemperature] = useState(
    String(settings?.temperature || 0.7)
  );
  const [maxTokens, setMaxTokens] = useState(
    String(settings?.max_tokens || 2000)
  );

  const handleSave = async () => {
    try {
      await updateSettingsMutation.mutateAsync({
        model: selectedModel,
        temperature: parseFloat(temperature),
        max_tokens: parseInt(maxTokens, 10),
      });
      toast({
        title: 'Settings saved',
        description: 'Chat settings have been updated successfully',
      });
    } catch (error) {
      toast({
        title: 'Save failed',
        description:
          error instanceof Error ? error.message : 'Failed to save settings',
        variant: 'destructive',
      });
    }
  };

  const handleClearHistory = async () => {
    try {
      await clearHistoryMutation.mutateAsync();
      toast({
        title: 'History cleared',
        description: 'Chat history has been cleared successfully',
      });
    } catch (error) {
      toast({
        title: 'Clear failed',
        description:
          error instanceof Error
            ? error.message
            : 'Failed to clear chat history',
        variant: 'destructive',
      });
    }
  };

  if (isLoading) {
    return (
      <div className="space-y-6">
        <Card className="p-6">
          <div className="flex items-center gap-2 mb-4">
            <MessageSquare className="w-5 h-5 text-blue-500" />
            <h3 className="text-lg font-medium">Loading settings...</h3>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <div className="flex items-center gap-2 mb-4">
          <MessageSquare className="w-5 h-5 text-blue-500" />
          <h3 className="text-lg font-medium">AI Model Configuration</h3>
        </div>

        <div className="space-y-4">
          {/* Model Selection */}
          <div className="space-y-2">
            <Label htmlFor="model">AI Model</Label>
            <Select
              value={selectedModel}
              onValueChange={value => setSelectedModel(value as AIModelType)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select AI model" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(AI_MODELS).map(([value, label]) => (
                  <SelectItem key={value} value={value}>
                    {label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {/* Temperature */}
          <div className="space-y-2">
            <Label htmlFor="temperature">Temperature</Label>
            <div className="flex items-center gap-2">
              <Input
                id="temperature"
                type="number"
                min="0"
                max="2"
                step="0.1"
                value={temperature}
                onChange={e => setTemperature(e.target.value)}
                className="w-24"
              />
              <span className="text-sm text-gray-500">
                (0 = deterministic, 2 = creative)
              </span>
            </div>
          </div>

          {/* Max Tokens */}
          <div className="space-y-2">
            <Label htmlFor="max-tokens">Max Tokens</Label>
            <div className="flex items-center gap-2">
              <Input
                id="max-tokens"
                type="number"
                min="100"
                max="4000"
                step="100"
                value={maxTokens}
                onChange={e => setMaxTokens(e.target.value)}
                className="w-24"
              />
              <span className="text-sm text-gray-500">(100 - 4000 tokens)</span>
            </div>
          </div>

          <Button
            onClick={handleSave}
            disabled={updateSettingsMutation.isLoading}
            className="mt-4"
          >
            {updateSettingsMutation.isLoading ? 'Saving...' : 'Save Settings'}
          </Button>
        </div>
      </Card>

      <Card className="p-6">
        <div className="flex items-center gap-2 mb-4">
          <MessageSquare className="w-5 h-5 text-blue-500" />
          <h3 className="text-lg font-medium">Chat History</h3>
        </div>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-500">
              Chat history is stored for 30 days
            </span>
            <Button
              variant="outline"
              size="sm"
              onClick={handleClearHistory}
              disabled={clearHistoryMutation.isLoading}
            >
              {clearHistoryMutation.isLoading ? 'Clearing...' : 'Clear History'}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
