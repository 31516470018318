import { useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToast } from '@/components/ui/use-toast';
import { useAuth } from '@/contexts/AuthContext';
import { useApi } from '@/hooks/useApi';
import {
  LoginFormValues,
  AuthResponse,
  AUTH_ERRORS,
} from '@/lib/validations/auth';

interface UseLoginOptions {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

export function useLogin(options: UseLoginOptions = {}) {
  const [isLoading, setIsLoading] = useState(false);
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();

  const api = useApi({
    onError: error => {
      setIsLoading(false);
      if (error instanceof Error) {
        const errorMessage = error.message || AUTH_ERRORS.SERVER_ERROR;
        toast({
          title: 'Login Failed',
          description: errorMessage,
          variant: 'destructive',
          duration: 5000,
        });
        options.onError?.(new Error(errorMessage));
      }
    },
  });

  // Get the redirect path from location state or default to root
  const from = (location.state as { from?: string })?.from || '/';

  const verifySession = useCallback(async () => {
    try {
      if (isAuthenticated) {
        console.log('Verifying session, user is authenticated');
        const response = await api.get<AuthResponse>('/auth/check');
        console.log('Session verification response:', response);

        if (response?.success) {
          console.log('Session is valid, redirecting to:', from);
          navigate(from, { replace: true });
        } else {
          console.log('Session verification failed');
        }
      } else {
        console.log('User is not authenticated, staying on login page');
      }
    } catch (error) {
      console.error('Session verification error:', error);
      // Silent fail - user will stay on login page
    }
  }, [isAuthenticated, api, navigate, from]);

  const handleLogin = async (values: LoginFormValues) => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      console.log('Attempting login with email:', values.email);
      const response = await login(values.email, values.password);
      console.log('Login response:', response);

      if (response.success) {
        toast({
          title: 'Welcome back!',
          description: 'You have successfully signed in.',
          duration: 3000,
        });

        console.log('Login successful, redirecting to:', from);
        // Use replace to prevent going back to login page
        navigate(from, { replace: true });
        options.onSuccess?.();
      } else {
        const errorMessage = response.error || AUTH_ERRORS.INVALID_CREDENTIALS;
        console.error('Login failed:', errorMessage);
        toast({
          title: 'Login Failed',
          description: errorMessage,
          variant: 'destructive',
          duration: 5000,
        });
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Login error:', error);
      const errorMessage =
        error instanceof Error ? error.message : AUTH_ERRORS.SERVER_ERROR;

      toast({
        title: 'Login Failed',
        description: errorMessage,
        variant: 'destructive',
        duration: 5000,
      });

      throw error instanceof Error ? error : new Error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    handleLogin,
    verifySession,
  };
}
