import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { MoreHorizontal, Users } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { useNavigate } from 'react-router-dom';

interface Tenant {
  id: string;
  name: string;
  status: 'active' | 'disabled';
  email: string;
  contactName: string;
  phone: string;
  maxUsers: number;
  currentUsers: number;
  domain: string;
  isPrimary: boolean;
  domainStatus: 'active' | 'inactive';
}

interface TenantsTableProps {
  tenants: Tenant[];
  onDeleteTenant: (id: string) => Promise<boolean>;
  onUpdateTenant: (id: string, data: Partial<Tenant>) => Promise<boolean>;
  onToggleStatus: (id: string) => Promise<boolean>;
}

export function TenantsTable({
  tenants,
  onDeleteTenant,
  onUpdateTenant,
  onToggleStatus,
}: TenantsTableProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<string | null>(null);

  const handleAction = async (
    id: string,
    action: 'delete' | 'toggle' | 'edit' | 'users'
  ) => {
    setLoading(id);
    try {
      switch (action) {
        case 'delete':
          await onDeleteTenant(id);
          break;
        case 'toggle':
          await onToggleStatus(id);
          break;
        case 'edit':
          // Navigate to edit page or open edit modal
          break;
        case 'users':
          navigate(`/tenants/${id}/users`);
          break;
      }
    } finally {
      setLoading(null);
    }
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Enterprise Name</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Contact</TableHead>
          <TableHead>Domain</TableHead>
          <TableHead>Users</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {tenants.map(tenant => (
          <TableRow key={tenant.id}>
            <TableCell className="font-medium">{tenant.name}</TableCell>
            <TableCell>
              <Badge
                variant={tenant.status === 'active' ? 'default' : 'destructive'}
              >
                {tenant.status}
              </Badge>
            </TableCell>
            <TableCell>
              <div className="space-y-1">
                <div>{tenant.contactName}</div>
                <div className="text-sm text-muted-foreground">
                  {tenant.email}
                </div>
              </div>
            </TableCell>
            <TableCell>
              <div className="space-y-1">
                <div>{tenant.domain}</div>
                <Badge
                  variant={
                    tenant.domainStatus === 'active' ? 'outline' : 'secondary'
                  }
                >
                  {tenant.isPrimary ? 'Primary' : 'Secondary'} •{' '}
                  {tenant.domainStatus}
                </Badge>
              </div>
            </TableCell>
            <TableCell>
              <div className="space-y-1">
                <div>
                  {tenant.currentUsers} / {tenant.maxUsers}
                </div>
                <div className="text-sm text-muted-foreground">users</div>
              </div>
            </TableCell>
            <TableCell className="text-right">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    className="h-8 w-8 p-0"
                    disabled={loading === tenant.id}
                  >
                    <span className="sr-only">Open menu</span>
                    <MoreHorizontal className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem
                    onClick={() => handleAction(tenant.id, 'users')}
                  >
                    <Users className="mr-2 h-4 w-4" />
                    View Users
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => handleAction(tenant.id, 'edit')}
                  >
                    Edit
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => handleAction(tenant.id, 'toggle')}
                  >
                    {tenant.status === 'active' ? 'Disable' : 'Enable'}
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="text-red-600"
                    onClick={() => handleAction(tenant.id, 'delete')}
                  >
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
