import { ConversionStatus, ConversionStatusConfig } from '@/types/conversion';

const ALLOWED_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/heic',
  'image/tiff',
  'application/pdf',
];

export const isValidFileType = (fileType: string): boolean => {
  return ALLOWED_FILE_TYPES.includes(fileType);
};

export const getStatusConfig = (
  status: ConversionStatus
): ConversionStatusConfig => {
  const configs: Record<ConversionStatus, ConversionStatusConfig> = {
    completed: { color: 'text-green-500', text: 'Completed' },
    failed: { color: 'text-red-500', text: 'Failed' },
    processing: { color: 'text-blue-500', text: 'Processing...' },
    pending: { color: 'text-yellow-500', text: 'Pending...' },
    uploading: { color: 'text-purple-500', text: 'Uploading...' },
  };

  return (
    configs[status] || {
      color: 'text-gray-500',
      text: status.charAt(0).toUpperCase() + status.slice(1),
    }
  );
};
