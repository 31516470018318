import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import AIModelSettings from '@/components/settings/AIModelSettings';
import ConvertSettings from '@/components/settings/ConvertSettings';
import HSLookupSettings from '@/components/settings/HSLookupSettings';
import FCLRateSettings from '@/components/settings/FCLRateSettings';
import ChatSettings from '@/components/settings/ChatSettings';
import DocumentCheckSettings from '@/components/settings/DocumentCheckSettings';
import { Separator } from '@/components/ui/separator';

export default function Settings() {
  return (
    <div className="container mx-auto py-6 max-w-5xl">
      <div className="space-y-6">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">Settings</h1>
          <p className="text-muted-foreground mt-2">
            Manage your AI models, templates, and feature settings
          </p>
        </div>

        <Separator />

        <Accordion type="single" collapsible className="w-full space-y-4">
          <AccordionItem
            value="ai-model-settings"
            className="border rounded-lg px-4 shadow-sm"
          >
            <AccordionTrigger className="hover:no-underline py-4">
              <div className="flex flex-col items-start gap-1">
                <div className="text-base font-semibold">AI Model Settings</div>
                <div className="text-sm text-muted-foreground">
                  Configure AI models and API keys
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="pt-4 pb-6">
              <AIModelSettings />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem
            value="convert-settings"
            className="border rounded-lg px-4 shadow-sm"
          >
            <AccordionTrigger className="hover:no-underline py-4">
              <div className="flex flex-col items-start gap-1">
                <div className="text-base font-semibold">
                  Convert to Excel Settings
                </div>
                <div className="text-sm text-muted-foreground">
                  Configure Excel conversion options
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="pt-4 pb-6">
              <ConvertSettings />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem
            value="hs-lookup-settings"
            className="border rounded-lg px-4 shadow-sm"
          >
            <AccordionTrigger className="hover:no-underline py-4">
              <div className="flex flex-col items-start gap-1">
                <div className="text-base font-semibold">
                  HS Lookup Settings
                </div>
                <div className="text-sm text-muted-foreground">
                  Manage HS code database and import options
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="pt-4 pb-6">
              <HSLookupSettings />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem
            value="fcl-rate-settings"
            className="border rounded-lg px-4 shadow-sm"
          >
            <AccordionTrigger className="hover:no-underline py-4">
              <div className="flex flex-col items-start gap-1">
                <div className="text-base font-semibold">FCL Rate Settings</div>
                <div className="text-sm text-muted-foreground">
                  Configure FCL rate calculation options
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="pt-4 pb-6">
              <FCLRateSettings />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem
            value="chat-settings"
            className="border rounded-lg px-4 shadow-sm"
          >
            <AccordionTrigger className="hover:no-underline py-4">
              <div className="flex flex-col items-start gap-1">
                <div className="text-base font-semibold">Chat Settings</div>
                <div className="text-sm text-muted-foreground">
                  Configure chat behavior and responses
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="pt-4 pb-6">
              <ChatSettings />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem
            value="document-check-settings"
            className="border rounded-lg px-4 shadow-sm"
          >
            <AccordionTrigger className="hover:no-underline py-4">
              <div className="flex flex-col items-start gap-1">
                <div className="text-base font-semibold">
                  Document Check Settings
                </div>
                <div className="text-sm text-muted-foreground">
                  Configure document validation rules
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="pt-4 pb-6">
              <DocumentCheckSettings />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}
