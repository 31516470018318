import { useState } from 'react';
import { useToast } from '@/components/ui/use-toast';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Card } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Brain, Plus, Trash2 } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  useAIModels,
  useFeatureModels,
  useAddAIModel,
  useDeleteAIModel,
  useUpdateFeatureModel,
} from '@/lib/api/services/ai-models';
import {
  type NewModel,
  type Provider,
  PROVIDER_NAMES,
  PROVIDER_ENV_KEYS,
  MODEL_FEATURES,
} from '@/lib/api/types/ai-models';

export default function AIModelSettings() {
  const { toast } = useToast();
  const [isAddingModel, setIsAddingModel] = useState(false);
  const [newModel, setNewModel] = useState<NewModel>({
    provider: 'openai',
    friendly_name: '',
    model_name: '',
  });

  const { data: models = [], isLoading: isLoadingModels } = useAIModels();
  const { data: featureSettings = [] } = useFeatureModels();
  const addModelMutation = useAddAIModel();
  const deleteModelMutation = useDeleteAIModel();
  const updateFeatureModelMutation = useUpdateFeatureModel();

  const handleAddModel = async () => {
    try {
      await addModelMutation.mutateAsync(newModel);
      setIsAddingModel(false);
      setNewModel({
        provider: 'openai',
        friendly_name: '',
        model_name: '',
      });
      toast({
        title: 'Success',
        description: 'AI model added successfully',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to add AI model',
        variant: 'destructive',
      });
    }
  };

  const handleDeleteModel = async (modelId: string) => {
    try {
      await deleteModelMutation.mutateAsync(modelId);
      toast({
        title: 'Success',
        description: 'AI model deleted successfully',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete AI model',
        variant: 'destructive',
      });
    }
  };

  const handleFeatureModelChange = async (feature: string, modelId: string) => {
    try {
      await updateFeatureModelMutation.mutateAsync({ feature, modelId });
      toast({
        title: 'Success',
        description: 'Feature model updated successfully',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update feature model',
        variant: 'destructive',
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-lg font-medium">AI Models</h3>
          <p className="text-sm text-muted-foreground">
            Configure AI models for different features
          </p>
        </div>
        <Dialog open={isAddingModel} onOpenChange={setIsAddingModel}>
          <DialogTrigger asChild>
            <Button>
              <Plus className="h-4 w-4 mr-2" />
              Add Model
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add AI Model</DialogTitle>
              <DialogDescription>
                Add a new AI model to use in different features
              </DialogDescription>
            </DialogHeader>
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="provider">Provider</Label>
                <Select
                  value={newModel.provider}
                  onValueChange={(value: Provider) =>
                    setNewModel(prev => ({ ...prev, provider: value }))
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select provider" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(PROVIDER_NAMES).map(([key, name]) => (
                      <SelectItem key={key} value={key}>
                        {name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="friendly_name">Friendly Name</Label>
                <Input
                  id="friendly_name"
                  value={newModel.friendly_name}
                  onChange={e =>
                    setNewModel(prev => ({
                      ...prev,
                      friendly_name: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="model_name">Model Name</Label>
                <Input
                  id="model_name"
                  value={newModel.model_name}
                  onChange={e =>
                    setNewModel(prev => ({
                      ...prev,
                      model_name: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => setIsAddingModel(false)}
                disabled={addModelMutation.isLoading}
              >
                Cancel
              </Button>
              <Button
                onClick={handleAddModel}
                disabled={addModelMutation.isLoading}
              >
                {addModelMutation.isLoading ? 'Adding...' : 'Add Model'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>

      <div className="space-y-4">
        {isLoadingModels ? (
          <div className="text-center py-4">Loading models...</div>
        ) : (
          models.map(model => (
            <Card key={model.id} className="p-4">
              <div className="flex items-start justify-between">
                <div className="space-y-1">
                  <div className="flex items-center gap-2">
                    <Brain className="h-4 w-4 text-muted-foreground" />
                    <span className="font-medium">{model.friendly_name}</span>
                  </div>
                  <div className="text-sm text-muted-foreground">
                    {PROVIDER_NAMES[model.provider]} - {model.model_name}
                  </div>
                </div>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => handleDeleteModel(model.id)}
                  disabled={deleteModelMutation.isLoading}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </Card>
          ))
        )}
      </div>

      <div className="space-y-4">
        <h4 className="text-md font-medium">Feature Models</h4>
        <ScrollArea className="h-[400px] rounded-md border p-4">
          <div className="space-y-4">
            {Object.entries(MODEL_FEATURES).map(([key, name]) => {
              const setting = featureSettings.find(s => s.feature_name === key);
              return (
                <div key={key} className="flex items-center justify-between">
                  <Label htmlFor={key}>{name}</Label>
                  <Select
                    value={setting?.model_id || ''}
                    onValueChange={value =>
                      handleFeatureModelChange(key, value)
                    }
                  >
                    <SelectTrigger className="w-[200px]">
                      <SelectValue placeholder="Select model" />
                    </SelectTrigger>
                    <SelectContent>
                      {models.map(model => (
                        <SelectItem key={model.id} value={model.id}>
                          {model.friendly_name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              );
            })}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}
