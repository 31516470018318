import React, { useRef, useState, useEffect } from 'react';
import { Upload } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useToast } from '@/components/ui/use-toast';

interface FileUploadAreaProps {
  selectedFile: File | null;
  isUploading: boolean;
  onFileSelect: (file: File | null) => void;
}

export function FileUploadArea({
  selectedFile,
  isUploading,
  onFileSelect,
}: FileUploadAreaProps) {
  const { toast } = useToast();
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dragCounter = useRef(0);
  const dropZoneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const preventDefaults = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragEnter = (e: DragEvent) => {
      preventDefaults(e);
      dragCounter.current += 1;
      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = 'copy';
      }
      setIsDragging(true);
    };

    const handleDragLeave = (e: DragEvent) => {
      preventDefaults(e);
      dragCounter.current -= 1;
      if (dragCounter.current === 0) {
        setIsDragging(false);
      }
    };

    const handleDragOver = (e: DragEvent) => {
      preventDefaults(e);
      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = 'copy';
      }
    };

    const handleDrop = (e: DragEvent) => {
      preventDefaults(e);
      setIsDragging(false);
      dragCounter.current = 0;

      const files = e.dataTransfer?.files;
      if (files?.length) {
        const file = files[0];
        if (validateExcelFile(file)) {
          onFileSelect(file);
        }
      }
    };

    const dropZone = dropZoneRef.current;
    if (dropZone) {
      dropZone.addEventListener('dragenter', handleDragEnter);
      dropZone.addEventListener('dragleave', handleDragLeave);
      dropZone.addEventListener('dragover', handleDragOver);
      dropZone.addEventListener('drop', handleDrop);

      return () => {
        dropZone.removeEventListener('dragenter', handleDragEnter);
        dropZone.removeEventListener('dragleave', handleDragLeave);
        dropZone.removeEventListener('dragover', handleDragOver);
        dropZone.removeEventListener('drop', handleDrop);
      };
    }
  }, [onFileSelect]);

  const validateExcelFile = (file: File): boolean => {
    const validTypes = [
      'application/vnd.ms-excel', // .xls
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    ];
    const isValidExtension =
      file.name.toLowerCase().endsWith('.xlsx') ||
      file.name.toLowerCase().endsWith('.xls');
    const isValidType = validTypes.includes(file.type);

    if (!isValidExtension || !isValidType) {
      toast({
        title: 'Invalid file type',
        description: 'Please upload only Excel files (.xlsx or .xls)',
        variant: 'destructive',
      });
      return false;
    }
    return true;
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && validateExcelFile(file)) {
      onFileSelect(file);
    } else if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset input if validation fails
    }
  };

  const handleClearFile = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (typeof onFileSelect === 'function') {
      onFileSelect(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <div
      ref={dropZoneRef}
      className={cn(
        'border-2 border-dashed rounded-lg p-8 text-center transition-all duration-200 ease-in-out',
        isDragging
          ? 'border-primary bg-primary/10 scale-[1.02]'
          : 'border-gray-300 hover:border-primary hover:bg-gray-50',
        isUploading ? 'pointer-events-none opacity-50' : 'cursor-pointer',
        'relative'
      )}
      onClick={() => !isUploading && fileInputRef.current?.click()}
    >
      <div className="flex flex-col items-center justify-center space-y-4">
        <Upload
          className={cn(
            'h-12 w-12 transition-colors duration-200',
            isDragging ? 'text-primary' : 'text-gray-400'
          )}
        />
        <div>
          <p className="text-lg font-medium">
            {isDragging
              ? 'Drop your Excel file here'
              : 'Drag and drop Excel file here, or click to select'}
          </p>
          <p className="text-sm text-gray-500 mt-1">
            Supports Excel files (.xlsx, .xls)
          </p>
        </div>
        {selectedFile && (
          <div className="text-sm text-gray-600 bg-gray-50 px-3 py-2 rounded-md flex items-center justify-between w-full max-w-md">
            <span className="truncate">{selectedFile.name}</span>
            <button
              onClick={handleClearFile}
              className="ml-2 text-gray-500 hover:text-red-500 transition-colors"
            >
              ×
            </button>
          </div>
        )}
        <input
          ref={fileInputRef}
          type="file"
          accept=".xlsx,.xls,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={handleFileInputChange}
          className="hidden"
          disabled={isUploading}
        />
      </div>
    </div>
  );
}
