import { useState, useRef, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Upload, Send, Loader2, FileText, X } from 'lucide-react';
import {
  useChatWithContext,
  useDocumentContext,
} from '@/lib/api/services/chat';
import {
  ChatMessage,
  DocumentContext,
  ChatResponse,
} from '@/lib/api/types/chat';
import { getApiUrl } from '@/lib/config';
import { useToast } from '@/hooks/use-toast';

interface ExtendedDocumentContext extends DocumentContext {
  id?: string;
  timestamp?: Date;
}

export default function Chat() {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [input, setInput] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [documentContext, setDocumentContext] =
    useState<ExtendedDocumentContext | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { toast } = useToast();
  const {
    messages: chatMessages,
    sendMessage,
    isLoading,
  } = useChatWithContext(documentContext);
  const contextMutation = useDocumentContext();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || isLoading) return;

    try {
      await sendMessage(input);
      setInput('');
    } catch (error) {
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to send message',
        variant: 'destructive',
      });
    }
  };

  const handleFileUpload = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch(getApiUrl('chat/context'), {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      const contextData = await response.json();
      setDocumentContext({
        ...contextData,
        timestamp: new Date(),
      });

      toast({
        title: 'Success',
        description: 'Document uploaded successfully',
      });

      setFile(file);
    } catch (error) {
      console.error('Upload error:', error);
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to upload document',
        variant: 'destructive',
      });
    }
  };

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Chat</h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <Card className="lg:col-span-3">
          <CardHeader>
            <CardTitle>Message History</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <ScrollArea className="h-[600px] pr-4">
              <div className="space-y-4">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`flex ${
                      message.role === 'user' ? 'justify-end' : 'justify-start'
                    }`}
                  >
                    <div
                      className={`rounded-lg p-4 max-w-[80%] ${
                        message.role === 'user'
                          ? 'bg-primary text-primary-foreground'
                          : 'bg-muted'
                      }`}
                    >
                      <p className="whitespace-pre-wrap">{message.content}</p>
                      <p className="text-xs mt-2 opacity-70">
                        {message.timestamp.toLocaleTimeString()}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </ScrollArea>

            <form onSubmit={handleSubmit} className="flex gap-2">
              <Input
                value={input}
                onChange={e => setInput(e.target.value)}
                placeholder="Type your message..."
                disabled={isLoading}
              />
              <Button type="submit" disabled={isLoading}>
                {isLoading ? (
                  <Loader2 className="h-4 w-4 animate-spin" />
                ) : (
                  <Send className="h-4 w-4" />
                )}
              </Button>
            </form>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Document Context</CardTitle>
            <CardDescription>
              Upload a document to provide context for the chat
            </CardDescription>
          </CardHeader>
          <CardContent>
            {documentContext ? (
              <div className="space-y-4">
                <div className="flex items-center justify-between p-3 bg-slate-50 rounded-lg">
                  <div className="flex items-center gap-2">
                    <FileText size={20} />
                    <div>
                      <p className="font-medium">{documentContext.filename}</p>
                      <p className="text-sm text-muted-foreground">
                        Added {documentContext.timestamp?.toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setDocumentContext(null)}
                  >
                    <X size={16} />
                  </Button>
                </div>
              </div>
            ) : (
              <div className="border-2 border-dashed rounded-lg p-6 text-center">
                <input
                  type="file"
                  id="file"
                  className="hidden"
                  accept=".pdf,.docx,.txt,.csv,.xlsx"
                  onChange={e => {
                    const file = e.target.files?.[0];
                    if (file) {
                      if (!file.name.match(/\.(pdf|docx?|txt|csv|xlsx)$/i)) {
                        toast({
                          title: 'Invalid file format',
                          description:
                            'Please upload a PDF, DOCX, TXT, CSV, or Excel file',
                          variant: 'destructive',
                        });
                        return;
                      }
                      if (file.size > 10 * 1024 * 1024) {
                        toast({
                          title: 'File too large',
                          description: 'Please upload a file smaller than 10MB',
                          variant: 'destructive',
                        });
                        return;
                      }
                      handleFileUpload(file);
                    }
                  }}
                />
                <label
                  htmlFor="file"
                  className="cursor-pointer flex flex-col items-center gap-2"
                >
                  <Upload size={24} />
                  <span className="font-medium">Click to upload document</span>
                  <span className="text-sm text-muted-foreground">
                    PDF, DOCX, TXT, CSV, or Excel files
                  </span>
                </label>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
