import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectSeparator,
} from '@/components/ui/select';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Loader2 } from 'lucide-react';
import {
  PRODUCT_TYPE_OPTIONS,
  MANUFACTURE_STAGE_OPTIONS,
  HS_DIGIT_OPTIONS,
} from '@/lib/api/constants/hs-lookup';
import type { HSLookupFormValues } from '@/lib/api/types/hs-lookup';
import type { UseFormReturn } from 'react-hook-form';

interface HSLookupFormProps {
  form: UseFormReturn<HSLookupFormValues>;
  isLoading: boolean;
  customProductType: string;
  isCustomProductType: boolean;
  onSubmit: (values: HSLookupFormValues) => Promise<void>;
  handleClear: () => void;
  handleProductTypeChange: (value: string) => void;
  handleCustomProductTypeChange: (value: string) => void;
}

export function HSLookupForm({
  form,
  isLoading,
  customProductType,
  isCustomProductType,
  onSubmit,
  handleClear,
  handleProductTypeChange,
  handleCustomProductTypeChange,
}: HSLookupFormProps) {
  return (
    <Card className="border-[#D1D5DB]">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
        <CardTitle className="text-lg font-semibold">
          Single Item Lookup
        </CardTitle>
        <Button
          variant="outline"
          size="sm"
          className="hover-accent-bg border bg-secondary/50"
          onClick={handleClear}
        >
          Clear
        </Button>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="product_overview"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm font-medium">
                    Product Overview
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Provide a detailed description of the product"
                      className="resize-none h-32 text-sm"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription className="text-xs text-gray-700">
                    Include key characteristics, composition, and any unique
                    features
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="product_function"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm font-medium">
                    What is the function/purpose of the product?
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Describe how the product works and what it's used for"
                      className="resize-none h-24 text-sm"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="product_type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">
                      Product Type
                    </FormLabel>
                    <Select
                      onValueChange={handleProductTypeChange}
                      value={isCustomProductType ? 'custom' : field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="h-9">
                          <SelectValue placeholder="Select product type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {PRODUCT_TYPE_OPTIONS.map(option => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                        <SelectSeparator />
                        <SelectItem value="custom">Custom Type</SelectItem>
                      </SelectContent>
                    </Select>
                    {isCustomProductType && (
                      <Input
                        placeholder="Enter custom product type"
                        value={customProductType}
                        onChange={e =>
                          handleCustomProductTypeChange(e.target.value)
                        }
                        className="mt-2"
                      />
                    )}
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="manufacture_stage"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">
                      Manufacturing Stage
                    </FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger className="h-9">
                          <SelectValue placeholder="Select stage" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {MANUFACTURE_STAGE_OPTIONS.map(option => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="materials"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm font-medium">
                    Materials
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="List the main materials used in the product"
                      className="resize-none text-sm"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="dimensions"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm font-medium">
                    Physical Specifications
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Dimensions, weight, or capacity specifications"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="industry"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm font-medium">
                    Industry/Sector
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Primary industry or sector where the product is used"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="origin_country"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">
                      Origin
                    </FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Country, State, or City" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="target_country"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">
                      Destination
                    </FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Country, State, or City" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="hs_digits"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm font-medium">
                    HS Code Digits
                  </FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger className="h-9">
                        <SelectValue placeholder="Select number of digits" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="6">6 digits</SelectItem>
                      <SelectItem value="8">8 digits</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit" className="w-full" disabled={isLoading}>
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Searching...
                </>
              ) : (
                'Search'
              )}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
