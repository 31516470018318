import { useState } from 'react';
import { fetchApi, uploadFile } from '../utils/fetch';
import {
  ChatMessage,
  ChatResponse,
  DocumentContext,
  DocumentContextResponse,
} from '../types/chat';

export function useChatWithContext(documentContext: DocumentContext | null) {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const sendMessage = async (content: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const userMessage: ChatMessage = {
        role: 'user',
        content,
        timestamp: new Date(),
      };

      setMessages(prev => [...prev, userMessage]);

      const response = await fetchApi<ChatResponse>('chat/message', {
        method: 'POST',
        body: JSON.stringify({
          message: content,
          context: documentContext,
        }),
      });

      if (response.success) {
        const assistantMessage: ChatMessage = {
          role: 'assistant',
          content: response.message,
          timestamp: new Date(),
        };

        setMessages(prev => [...prev, assistantMessage]);
      } else {
        setError(response.error || 'Failed to get response');
      }
    } catch (error) {
      setError(
        error instanceof Error ? error.message : 'Failed to send message'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const clearMessages = () => {
    setMessages([]);
    setError(null);
  };

  return {
    messages,
    isLoading,
    error,
    sendMessage,
    clearMessages,
  };
}

export function useDocumentContext() {
  const [context, setContext] = useState<DocumentContext | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const uploadDocument = async (file: File) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await uploadFile<DocumentContextResponse>(
        'doc-check/upload',
        file
      );
      setContext(response.context);
    } catch (error) {
      setError(
        error instanceof Error ? error.message : 'Failed to upload document'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const clearContext = () => {
    setContext(null);
    setError(null);
  };

  return {
    context,
    isLoading,
    error,
    uploadDocument,
    clearContext,
  };
}
