export const formatHSCode = (code?: string, digits: number = 8): string => {
  if (!code) return 'N/A';

  // Clean the code to only include digits
  const cleanCode = code.replace(/\D/g, '');

  // Pad with zeros if needed
  const paddedCode = cleanCode.padEnd(digits, '0');

  // Format based on number of digits
  const parts = [];
  parts.push(paddedCode.slice(0, 4)); // First 4 digits together

  // Add remaining pairs
  const remainingDigits = Math.min(digits - 4, paddedCode.length - 4);
  for (let i = 0; i < remainingDigits; i += 2) {
    parts.push(paddedCode.slice(4 + i, 6 + i));
  }

  return parts.join('.');
};

export function createFeedbackPayload(
  result_id: string,
  metrics: {
    accuracy: number;
    codeSpecificity: number;
    reasoningClarity: number;
    tradingRequirements: number;
    alternativesQuality: number;
  },
  comments: string
) {
  return {
    result_id,
    feedback: {
      metrics: {
        accuracy: metrics.accuracy,
        code_specificity: metrics.codeSpecificity,
        reasoning_clarity: metrics.reasoningClarity,
        trading_requirements: metrics.tradingRequirements,
        alternatives_quality: metrics.alternativesQuality,
      },
      comments,
      timestamp: new Date().toISOString(),
    },
  };
}
