import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { MoreVertical, Trash2, Edit } from 'lucide-react';
import { User, UserUpdateData } from '@/types/user';
import { getUserPermissions, formatPermission } from '@/lib/permissions';
import { EditUserDialog } from './EditUserDialog';

interface UsersTableProps {
  users: User[];
  onDeleteUser: (id: string) => Promise<boolean>;
  onUpdateUser: (userData: UserUpdateData) => Promise<boolean>;
  onUpdatePassword: (id: string, password: string) => Promise<boolean>;
}

export function UsersTable({
  users,
  onDeleteUser,
  onUpdateUser,
  onUpdatePassword,
}: UsersTableProps) {
  return (
    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="whitespace-nowrap px-3 py-3.5">
                  Username
                </TableHead>
                <TableHead className="whitespace-nowrap px-3 py-3.5">
                  Email
                </TableHead>
                <TableHead className="whitespace-nowrap px-3 py-3.5">
                  Name
                </TableHead>
                <TableHead className="whitespace-nowrap px-3 py-3.5">
                  Role
                </TableHead>
                <TableHead className="whitespace-nowrap px-3 py-3.5">
                  Permissions
                </TableHead>
                <TableHead className="whitespace-nowrap px-3 py-3.5">
                  Created At
                </TableHead>
                <TableHead className="relative whitespace-nowrap px-3 py-3.5">
                  <span className="sr-only">Actions</span>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {users.map(user => (
                <TableRow key={user.id}>
                  <TableCell className="whitespace-nowrap px-3 py-4">
                    {user.username}
                  </TableCell>
                  <TableCell className="whitespace-nowrap px-3 py-4">
                    {user.email}
                  </TableCell>
                  <TableCell className="whitespace-nowrap px-3 py-4">
                    {user.name}
                  </TableCell>
                  <TableCell className="whitespace-nowrap px-3 py-4">
                    <Badge
                      variant={user.role === 'admin' ? 'default' : 'secondary'}
                    >
                      {user.role}
                    </Badge>
                  </TableCell>
                  <TableCell className="px-3 py-4">
                    <div className="flex flex-wrap gap-1">
                      {getUserPermissions(user).map(permission => (
                        <Badge
                          key={permission}
                          variant="outline"
                          className="text-xs"
                        >
                          {formatPermission(permission)}
                        </Badge>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell className="whitespace-nowrap px-3 py-4">
                    {new Date(user.created_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell className="relative whitespace-nowrap px-3 py-4">
                    <div className="flex items-center space-x-2">
                      <EditUserDialog
                        user={user}
                        onUpdateUser={onUpdateUser}
                        onUpdatePassword={onUpdatePassword}
                      />
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" className="h-8 w-8 p-0">
                            <MoreVertical className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuItem
                            onClick={() => onDeleteUser(user.id)}
                            className="text-red-600"
                          >
                            <Trash2 className="mr-2 h-4 w-4" />
                            Delete
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}
