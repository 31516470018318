import { useCallback } from 'react';
import { useAuth } from '@/hooks/useAuth';

const API_BASE_URL = 'https://api.shipmate.fpsaus.com.au';

interface ApiError {
  error: string;
  details?: string;
}

interface ApiOptions {
  headers?: Record<string, string>;
}

export function useApi() {
  const { token } = useAuth();

  const request = useCallback(
    async <T>(
      method: string,
      path: string,
      body?: unknown,
      options: ApiOptions = {}
    ): Promise<T> => {
      const headers = {
        ...options.headers,
      };

      if (!(body instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
      }

      // Always add auth token if available
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      } else {
        // Get token from localStorage as fallback
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
          headers['Authorization'] = `Bearer ${storedToken}`;
        }
      }

      const response = await fetch(`${API_BASE_URL}${path}`, {
        method,
        headers,
        body: body instanceof FormData ? body : JSON.stringify(body),
        credentials: 'include',
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error((data as ApiError).error || 'An error occurred');
      }

      const data = await response.json();
      return data as T;
    },
    [token]
  );

  return {
    get: <T>(path: string, options?: ApiOptions) =>
      request<T>('GET', path, undefined, options),
    post: <T>(path: string, body?: unknown, options?: ApiOptions) =>
      request<T>('POST', path, body, options),
    put: <T>(path: string, body?: unknown, options?: ApiOptions) =>
      request<T>('PUT', path, body, options),
    delete: <T>(path: string, options?: ApiOptions) =>
      request<T>('DELETE', path, undefined, options),
  };
}
