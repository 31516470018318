export type AIModelType =
  | 'gpt-4'
  | 'gpt-3.5-turbo'
  | 'claude-2'
  | 'pplx-7b-chat'
  | 'pplx-70b-chat';

export interface ChatSetting {
  id: number;
  key: string;
  value: string;
  category: string;
}

export interface ChatSettings {
  model: AIModelType;
  temperature: number;
  max_tokens: number;
}

export const AI_MODELS = {
  'gpt-4': 'GPT-4',
  'gpt-3.5-turbo': 'GPT-3.5 Turbo',
  'claude-2': 'Claude 2',
  'pplx-7b-chat': 'Perplexity 7B Chat',
  'pplx-70b-chat': 'Perplexity 70B Chat',
} as const;
