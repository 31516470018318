import { useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@/components/ui/card';
import { Loader2, Settings } from 'lucide-react';
import { AddEnterpriseDialog } from '@/components/tenants/AddEnterpriseDialog';
import { AddUserDialog } from '@/components/users/AddUserDialog';
import { TenantsTable } from '@/components/tenants/TenantsTable';
import { useTenantManagement } from '@/hooks/useTenantManagement';
import { Button } from '@/components/ui/button';

export default function TenantManagement() {
  const {
    tenants,
    isLoading,
    fetchTenants,
    addTenant,
    updateTenant,
    deleteTenant,
    toggleTenantStatus,
  } = useTenantManagement();

  useEffect(() => {
    fetchTenants();
  }, []);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold">Enterprise Management</h1>
          <p className="text-muted-foreground">
            Manage enterprise tenants and their users
          </p>
        </div>
        <div className="space-x-4">
          <AddUserDialog
            onAddUser={userData => console.log('Add user:', userData)}
          />
          <AddEnterpriseDialog onAddEnterprise={addTenant} />
        </div>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Enterprise Tenants</CardTitle>
          <CardDescription>
            View and manage all enterprise tenants in the system
          </CardDescription>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="flex justify-center py-8">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <TenantsTable
              tenants={tenants}
              onDeleteTenant={deleteTenant}
              onUpdateTenant={updateTenant}
              onToggleStatus={toggleTenantStatus}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
}
