import { ApiResponse } from './common';

export interface HSResult {
  code: string;
  description: string;
  confidence: number;
  reasoning: string;
  status?: 'processing' | 'completed' | 'failed';
  classification_notes: {
    product_type: string;
    manufacture_stage: string;
    key_materials: string;
  };
  trade_considerations: {
    duty_rates: string;
    restrictions: string;
    special_requirements: string;
    trade_agreements: string;
    warnings: string;
    labeling_requirements: string;
    packaging_regulations: string;
    rebates: string;
    excise_tax: string;
    import_permits: string;
    export_permits: string;
  };
  hierarchy: {
    chapter: {
      code: string;
      description: string;
    };
    heading: {
      code: string;
      description: string;
    };
  };
  alternatives: Array<{
    code: string;
    description: string;
    confidence: number;
    reasoning: string;
    differentiators: string;
  }>;
}

export interface HSLookupResponse {
  success: boolean;
  lookup_id: string;
  result?: HSResult;
  error?: string;
  jobId?: string;
}

export interface ErrorResponse {
  message: string;
  details?: string;
  debug?: string;
  canRetry?: boolean;
}

export interface HSLookupFormValues {
  product_overview: string;
  product_function: string;
  product_type: string;
  manufacture_stage: string;
  materials: string;
  dimensions: string;
  industry: string;
  origin_country: string;
  target_country: string;
  hs_digits: string;
  jobId?: string;
}

export interface RecentBulkUpload {
  id?: string;
  filename?: string;
  status?: string;
  timestamp?: string;
  recordCount?: number;
}

export interface FeedbackData {
  accuracy: number;
  codeSpecificity: number;
  reasoningClarity: number;
  tradingRequirements: number;
  alternativesQuality: number;
  comments: string;
}

export interface CascadeState {
  stage: 'initial' | 'detailed' | 'validation' | 'complete';
  progress: number;
}

export interface BulkHSLookupResult {
  id: string;
  status: 'success' | 'failed' | 'processing';
  code?: string;
  description?: string;
  error?: string;
  product_description?: string;
  confidence?: number;
  reasoning?: string;
}

export interface BulkHSLookupResponse {
  jobId: string;
  results: BulkHSLookupResult[];
  stats: {
    total: number;
    processed: number;
    success: number;
    failed: number;
  };
}

export interface HSDocument {
  id: string;
  name: string;
  type: string;
  created_at: string;
}

export interface HSImportStats {
  totalProcessed: number;
  successful: number;
  failed: number;
  errors: string[];
}

export interface HSImportResponse extends ApiResponse {
  stats: HSImportStats;
}

export interface HSDocumentsResponse {
  documents: HSDocument[];
}

export interface HSImportRequest {
  file: File;
  country: string;
  version: string;
  headerRow?: boolean;
}

export interface HSDeleteRequest {
  country: string;
  version: string;
}

export interface BulkHSLookupRequest {
  file: File;
  hsDigits: string;
}

export interface BulkHSLookupStatus {
  status: 'pending' | 'processing' | 'completed' | 'failed';
  progress: number;
  error?: string;
  result?: BulkHSLookupResult;
}

export const COUNTRIES = {
  AU: 'Australia',
  NZ: 'New Zealand',
  US: 'United States',
  UK: 'United Kingdom',
  CA: 'Canada',
} as const;

export const HS_VERSIONS = {
  '2022': 'HS 2022',
  '2017': 'HS 2017',
  '2012': 'HS 2012',
} as const;

export type HSResultProcessing = HSResult & { status: 'processing' };
