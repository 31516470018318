import { useState, useEffect } from 'react';
import { useToast } from '@/components/ui/use-toast';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Ship,
  Upload,
  Trash2,
  AlertCircle,
  Archive,
  FileText,
} from 'lucide-react';
import { cn } from '@/lib/utils';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  useUploadFCLRate,
  useDeleteFCLRate,
  useFCLRateHistory,
  useProcessingStatus,
  useDownloadFCLRateFile,
  useArchiveFCLRate,
} from '@/lib/api/fcl';
import { FCLRateHistory } from '@/types/rates';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Progress } from '@/components/ui/progress';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { useAuth } from '@/contexts/AuthContext';
import { format } from 'date-fns';
import { Badge } from '@/components/ui/badge';

const RATE_LIMIT = 5; // Maximum uploads per minute
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

interface ProcessingStatus {
  status: 'pending' | 'processing' | 'completed' | 'failed';
  error_message: string | null;
  details?: {
    stage?: string;
    progress?: number;
  };
}

export default function FCLRateSettings() {
  const { toast } = useToast();
  const { token } = useAuth();
  const [file, setFile] = useState<File | null>(null);
  const [carrierName, setCarrierName] = useState('');
  const [validityFrom, setValidityFrom] = useState('');
  const [validityTo, setValidityTo] = useState('');
  const [currentUploadId, setCurrentUploadId] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const [lastUploadTime, setLastUploadTime] = useState(Date.now());

  const uploadMutation = useUploadFCLRate();
  const deleteMutation = useDeleteFCLRate();
  const archiveMutation = useArchiveFCLRate();
  const downloadMutation = useDownloadFCLRateFile();
  const { data: rateHistory, isLoading } = useFCLRateHistory();
  const { data: processingStatus } = useProcessingStatus(currentUploadId || '');

  // Reset rate limit counter every minute
  useEffect(() => {
    const interval = setInterval(() => {
      if (Date.now() - lastUploadTime >= 60000) {
        setUploadCount(0);
        setLastUploadTime(Date.now());
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [lastUploadTime]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      if (
        selectedFile.type !==
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        selectedFile.type !== 'application/pdf'
      ) {
        toast({
          title: 'Invalid file type',
          description: 'Please upload an Excel (.xlsx) or PDF file',
          variant: 'destructive',
        });
        return;
      }

      if (selectedFile.size > MAX_FILE_SIZE) {
        toast({
          title: 'File too large',
          description: 'Maximum file size is 10MB',
          variant: 'destructive',
        });
        return;
      }

      setFile(selectedFile);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!file || !carrierName || !validityFrom || !validityTo || !token) {
      toast({
        title: 'Missing information',
        description:
          'Please fill in all required fields and ensure you are logged in',
        variant: 'destructive',
      });
      return;
    }

    // Check rate limit
    if (uploadCount >= RATE_LIMIT) {
      toast({
        title: 'Rate limit exceeded',
        description: 'Please wait before uploading more files',
        variant: 'destructive',
      });
      return;
    }

    setIsUploading(true);
    try {
      // Generate a unique file key for R2 storage with proper path
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      const sanitizedFileName = file.name.replace(/[^a-zA-Z0-9.-]/g, '_');
      const fileKey = `fcl-rates/active/${timestamp}_${carrierName}_${sanitizedFileName}`;

      const response = await uploadMutation.mutateAsync({
        carrier_name: carrierName,
        validity_from: validityFrom,
        validity_to: validityTo,
        file,
        file_key: fileKey,
      });

      setCurrentUploadId(response.historyId);
      setUploadCount(prev => prev + 1);
      setLastUploadTime(Date.now());

      toast({
        title: 'Upload successful',
        description: 'Your file is being processed and vectorized',
      });

      // Reset form
      setFile(null);
      setCarrierName('');
      setValidityFrom('');
      setValidityTo('');
    } catch (error) {
      console.error('Upload error:', error);
      toast({
        title: 'Upload failed',
        description:
          error instanceof Error ? error.message : 'An error occurred',
        variant: 'destructive',
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleDelete = async (id: string) => {
    if (!token) {
      toast({
        title: 'Authentication required',
        description: 'Please log in to delete rate files',
        variant: 'destructive',
      });
      return;
    }

    try {
      await deleteMutation.mutateAsync(id);
      toast({
        title: 'Delete successful',
        description: 'The rate file has been deleted',
      });
    } catch (error) {
      toast({
        title: 'Delete failed',
        description:
          error instanceof Error ? error.message : 'An error occurred',
        variant: 'destructive',
      });
    }
  };

  const handleArchive = async (id: string, fileKey: string) => {
    if (!token) {
      toast({
        title: 'Authentication required',
        description: 'Please log in to archive rate files',
        variant: 'destructive',
      });
      return;
    }

    try {
      // Move file to archived folder in R2
      const newFileKey = fileKey.replace('active/', 'archived/');

      await archiveMutation.mutateAsync({
        id,
        file_key: newFileKey,
      });

      toast({
        title: 'Archive successful',
        description: 'The rate file has been archived',
      });
    } catch (error) {
      toast({
        title: 'Archive failed',
        description:
          error instanceof Error ? error.message : 'An error occurred',
        variant: 'destructive',
      });
    }
  };

  const handleDownload = async (ratesKey: string) => {
    if (!token) {
      toast({
        title: 'Authentication required',
        description: 'Please log in to download rate files',
        variant: 'destructive',
      });
      return;
    }

    try {
      await downloadMutation.mutateAsync(ratesKey);
    } catch (error) {
      toast({
        title: 'Download failed',
        description:
          error instanceof Error ? error.message : 'An error occurred',
        variant: 'destructive',
      });
    }
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'processing':
        return 'bg-yellow-500';
      case 'completed':
        return 'bg-green-500';
      case 'failed':
        return 'bg-red-500';
      default:
        return 'bg-gray-500';
    }
  };

  const isFileExpired = (validityTo: string) => {
    return new Date(validityTo) < new Date();
  };

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Upload FCL Rates</CardTitle>
          <CardDescription>
            Upload your FCL rates file in Excel (.xlsx) or PDF format
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="carrier">Carrier Name *</Label>
              <Input
                id="carrier"
                value={carrierName}
                onChange={e => setCarrierName(e.target.value)}
                required
              />
              <p className="text-sm text-muted-foreground">
                This carrier name will be applied to all rates in the file
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="validFrom">Valid From *</Label>
                <Input
                  id="validFrom"
                  type="date"
                  value={validityFrom}
                  onChange={e => setValidityFrom(e.target.value)}
                  required
                />
                <p className="text-sm text-muted-foreground">
                  Start date for these rates
                </p>
              </div>
              <div className="space-y-2">
                <Label htmlFor="validTo">Valid To *</Label>
                <Input
                  id="validTo"
                  type="date"
                  value={validityTo}
                  onChange={e => setValidityTo(e.target.value)}
                  required
                />
                <p className="text-sm text-muted-foreground">
                  End date for these rates
                </p>
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="file">Rate File *</Label>
              <Input
                id="file"
                type="file"
                onChange={handleFileChange}
                accept=".xlsx,application/pdf"
                required
              />
              <p className="text-sm text-muted-foreground">
                Maximum file size: 10MB
              </p>
            </div>
            <Button
              type="submit"
              disabled={
                isUploading || !file || !token || uploadCount >= RATE_LIMIT
              }
              className="w-full"
            >
              {isUploading ? 'Uploading...' : 'Upload'}
            </Button>
            {uploadCount >= RATE_LIMIT && (
              <p className="text-sm text-red-500">
                Rate limit reached. Please wait before uploading more files.
              </p>
            )}
          </form>

          {processingStatus && currentUploadId && (
            <div className="mt-4 space-y-2">
              <Alert
                variant={
                  processingStatus.status === 'failed'
                    ? 'destructive'
                    : 'default'
                }
              >
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Processing Status</AlertTitle>
                <AlertDescription>
                  {processingStatus.status === 'processing'
                    ? processingStatus.details?.stage ||
                      'Your file is being processed and vectorized...'
                    : processingStatus.status === 'completed'
                      ? 'Processing completed successfully'
                      : `Processing failed: ${processingStatus.error_message}`}
                </AlertDescription>
              </Alert>
              {processingStatus.status === 'processing' && (
                <div className="space-y-2">
                  <Progress
                    value={processingStatus.details?.progress || 0}
                    className="w-full"
                  />
                  <p className="text-sm text-muted-foreground text-center">
                    {processingStatus.details?.progress || 0}% Complete
                  </p>
                  {processingStatus.details?.stage && (
                    <p className="text-sm text-muted-foreground text-center">
                      {processingStatus.details.stage}
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Uploaded Rates</CardTitle>
          <CardDescription>
            View and manage your uploaded rate files
          </CardDescription>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div>Loading...</div>
          ) : !rateHistory?.length ? (
            <div className="text-center text-muted-foreground">
              No rate files uploaded yet
            </div>
          ) : (
            <ScrollArea className="h-[400px]">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Carrier</TableHead>
                    <TableHead>File Name</TableHead>
                    <TableHead>Upload Date</TableHead>
                    <TableHead>Validity</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {rateHistory.map(rate => (
                    <TableRow
                      key={rate.id}
                      className={cn(
                        rate.is_archived && 'opacity-50',
                        isFileExpired(rate.validity_to) && 'bg-red-50'
                      )}
                    >
                      <TableCell>{rate.carrier_name}</TableCell>
                      <TableCell className="flex items-center gap-2">
                        <FileText className="h-4 w-4" />
                        {rate.file_name}
                      </TableCell>
                      <TableCell>
                        {format(new Date(rate.upload_date), 'dd/MM/yyyy HH:mm')}
                      </TableCell>
                      <TableCell>
                        {format(new Date(rate.validity_from), 'dd/MM/yyyy')} -{' '}
                        {format(new Date(rate.validity_to), 'dd/MM/yyyy')}
                        {isFileExpired(rate.validity_to) && (
                          <Badge variant="destructive" className="ml-2">
                            Expired
                          </Badge>
                        )}
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center gap-2">
                          <div
                            className={cn(
                              'h-2 w-2 rounded-full',
                              getStatusColor(rate.status)
                            )}
                          />
                          <span>{rate.status}</span>
                          {rate.is_archived && (
                            <Badge variant="secondary">Archived</Badge>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center gap-2">
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleDownload(rate.rates_key)}
                            disabled={downloadMutation.isLoading}
                            title="Download"
                          >
                            <Ship className="h-4 w-4" />
                          </Button>
                          {!rate.is_archived && (
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() =>
                                handleArchive(rate.id, rate.file_key)
                              }
                              disabled={archiveMutation.isLoading}
                              title="Archive"
                            >
                              <Archive className="h-4 w-4" />
                            </Button>
                          )}
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleDelete(rate.id)}
                            disabled={deleteMutation.isLoading}
                            title="Delete"
                          >
                            <Trash2 className="h-4 w-4 text-red-500" />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ScrollArea>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
