import { useRef, useState } from 'react';
import { Upload } from 'lucide-react';
import { cn } from '@/lib/utils';

interface FileUploadAreaProps {
  selectedFiles: File[];
  isUploading: boolean;
  onFileSelect: (files: File[]) => void;
}

export function FileUploadArea({
  selectedFiles,
  isUploading,
  onFileSelect,
}: FileUploadAreaProps) {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0) {
      onFileSelect(files);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? Array.from(e.target.files) : [];
    if (files.length > 0) {
      onFileSelect(files);
    }
  };

  return (
    <div
      className={cn(
        'border-2 border-dashed rounded-lg p-6 text-center cursor-pointer transition-colors',
        isDragging
          ? 'border-primary bg-primary/10'
          : 'border-gray-300 hover:border-primary',
        isUploading ? 'pointer-events-none opacity-50' : '',
        'relative'
      )}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={() => !isUploading && fileInputRef.current?.click()}
    >
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        onChange={handleFileSelect}
        accept=".png,.jpg,.jpeg,.heic,.tiff,.pdf"
        disabled={isUploading}
        multiple
      />
      <div className="space-y-2">
        <Upload className="mx-auto h-8 w-8 text-gray-400" />
        <div className="text-sm">
          {selectedFiles.length > 0 ? (
            <div className="space-y-1">
              <p className="text-primary">
                {selectedFiles.length} file(s) selected
              </p>
              <div className="max-h-24 overflow-y-auto">
                {selectedFiles.map((file, index) => (
                  <p key={index} className="text-sm text-gray-600">
                    {file.name}
                  </p>
                ))}
              </div>
            </div>
          ) : (
            <>
              <p>Drag and drop files here, or click to select</p>
              <p className="text-gray-500">
                Supports PNG, JPEG, HEIC, TIFF, and PDF
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
