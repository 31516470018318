import { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useToast } from '@/components/ui/use-toast';
import type {
  HSLookupFormValues,
  HSResult,
  HSResultProcessing,
  ErrorResponse,
  CascadeState,
} from '@/lib/api/types/hs-lookup';
import { useHSLookup as useHSLookupMutation } from '@/lib/api/services/hs-lookup';

export function useHSLookup() {
  const { toast } = useToast();
  const [result, setResult] = useState<HSResult | null>(null);
  const [lookup_id, setLookupId] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [isDetailedMode, setIsDetailedMode] = useState(true);
  const [customProductType, setCustomProductType] = useState('');
  const [isCustomProductType, setIsCustomProductType] = useState(false);
  const [cascadeState, setCascadeState] = useState<CascadeState>({
    stage: 'initial',
    progress: 0,
  });
  const [currentJobId, setCurrentJobId] = useState<string | null>(null);

  // Refs for polling control
  const pollingIntervalRef = useRef<NodeJS.Timeout>();
  const isPollingRef = useRef(false);
  const mountedRef = useRef(true);

  const form = useForm<HSLookupFormValues>({
    defaultValues: {
      hs_digits: '8',
    },
  });

  const lookupMutation = useHSLookupMutation();

  const onSubmit = async (values: HSLookupFormValues) => {
    setIsLoading(true);
    setError(null);
    setResult(null);
    setLookupId(undefined);

    try {
      const response = await lookupMutation.mutateAsync(values);
      if (response.jobId) {
        setCurrentJobId(response.jobId);
        if (response.result) {
          const processingResult: HSResultProcessing = {
            ...response.result,
            status: 'processing',
          };
          setResult(processingResult);
        }
      } else if (response.result) {
        setResult(response.result);
      }
      setLookupId(response.lookup_id);
    } catch (err) {
      console.error('Error getting classification:', err);
      setError(
        err instanceof Error
          ? { message: err.message }
          : { message: 'Failed to get classification' }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const refreshResult = async () => {
    if (!currentJobId || !mountedRef.current) return;

    try {
      const response = await lookupMutation.mutateAsync({
        ...form.getValues(),
        jobId: currentJobId,
      });

      if (!mountedRef.current) return;

      if (response.result) {
        setResult(response.result);
        if (
          response.result.status === 'completed' ||
          response.result.status === 'failed'
        ) {
          setCurrentJobId(null);
          // Stop polling when job is complete or failed
          if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
            pollingIntervalRef.current = undefined;
            isPollingRef.current = false;
          }
        }
      }
    } catch (error) {
      console.error('Error refreshing result:', error);
      if (mountedRef.current) {
        // Stop polling on error
        if (pollingIntervalRef.current) {
          clearInterval(pollingIntervalRef.current);
          pollingIntervalRef.current = undefined;
          isPollingRef.current = false;
        }
      }
    }
  };

  // Set up polling for processing jobs
  useEffect(() => {
    mountedRef.current = true;

    const startPolling = async () => {
      if (!currentJobId || isPollingRef.current) return;

      await refreshResult();

      if (!mountedRef.current) return;

      if (currentJobId && !pollingIntervalRef.current) {
        isPollingRef.current = true;
        pollingIntervalRef.current = setInterval(refreshResult, 5000);
      }
    };

    startPolling();

    return () => {
      mountedRef.current = false;
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = undefined;
      }
      isPollingRef.current = false;
    };
  }, [currentJobId]);

  const handleClear = () => {
    form.reset();
    setResult(null);
    setError(null);
    setCurrentJobId(null);
    setCascadeState({ stage: 'initial', progress: 0 });
    // Clear any ongoing polling
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = undefined;
      isPollingRef.current = false;
    }
  };

  const handleProductTypeChange = (value: string) => {
    if (value === 'custom') {
      setIsCustomProductType(true);
    } else {
      setIsCustomProductType(false);
      form.setValue('product_type', value);
    }
  };

  const handleCustomProductTypeChange = (value: string) => {
    setCustomProductType(value);
    form.setValue('product_type', value);
  };

  return {
    form,
    result,
    lookup_id,
    isLoading,
    error,
    isDetailedMode,
    customProductType,
    isCustomProductType,
    cascadeState,
    onSubmit,
    handleClear,
    handleProductTypeChange,
    handleCustomProductTypeChange,
    setIsDetailedMode,
    refreshResult,
  };
}
