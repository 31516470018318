import { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { z } from 'zod';

const enterpriseSchema = z.object({
  name: z.string().min(1, 'Enterprise name is required'),
  status: z.enum(['active', 'disabled']),
  email: z.string().email('Invalid email address'),
  contactName: z.string().min(1, 'Contact name is required'),
  phone: z.string().min(1, 'Phone number is required'),
  maxUsers: z.number().min(1, 'Must allow at least 1 user'),
  domain: z.string().min(1, 'Domain is required'),
  isPrimary: z.boolean(),
  domainStatus: z.enum(['active', 'inactive']),
});

type EnterpriseData = z.infer<typeof enterpriseSchema>;

interface AddEnterpriseDialogProps {
  onAddEnterprise: (data: EnterpriseData) => Promise<boolean>;
}

export function AddEnterpriseDialog({
  onAddEnterprise,
}: AddEnterpriseDialogProps) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<EnterpriseData>({
    name: '',
    status: 'active',
    email: '',
    contactName: '',
    phone: '',
    maxUsers: 1,
    domain: '',
    isPrimary: true,
    domainStatus: 'active',
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const validatedData = enterpriseSchema.parse(formData);
      const success = await onAddEnterprise(validatedData);
      if (success) {
        setOpen(false);
        setFormData({
          name: '',
          status: 'active',
          email: '',
          contactName: '',
          phone: '',
          maxUsers: 1,
          domain: '',
          isPrimary: true,
          domainStatus: 'active',
        });
      }
    } catch (error) {
      console.error('Validation error:', error);
    }
  };

  const handleDomainChange = (value: string) => {
    // Strip https:// and backslashes from domain
    const cleanDomain = value.replace(/^https?:\/\//, '').replace(/\\/g, '');
    setFormData({ ...formData, domain: cleanDomain });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Add Enterprise</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>Add Enterprise</DialogTitle>
            <DialogDescription>
              Add a new enterprise tenant to the system
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                Enterprise Name
              </Label>
              <Input
                id="name"
                value={formData.name}
                onChange={e =>
                  setFormData({ ...formData, name: e.target.value })
                }
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="status" className="text-right">
                Status
              </Label>
              <div className="flex items-center space-x-2 col-span-3">
                <Switch
                  id="status"
                  checked={formData.status === 'active'}
                  onCheckedChange={checked =>
                    setFormData({
                      ...formData,
                      status: checked ? 'active' : 'disabled',
                    })
                  }
                />
                <span>
                  {formData.status === 'active' ? 'Active' : 'Disabled'}
                </span>
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="email" className="text-right">
                Email
              </Label>
              <Input
                id="email"
                type="email"
                value={formData.email}
                onChange={e =>
                  setFormData({ ...formData, email: e.target.value })
                }
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="contactName" className="text-right">
                Contact Name
              </Label>
              <Input
                id="contactName"
                value={formData.contactName}
                onChange={e =>
                  setFormData({ ...formData, contactName: e.target.value })
                }
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="phone" className="text-right">
                Phone
              </Label>
              <Input
                id="phone"
                type="tel"
                value={formData.phone}
                onChange={e =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="maxUsers" className="text-right">
                Max Users
              </Label>
              <Input
                id="maxUsers"
                type="number"
                min="1"
                value={formData.maxUsers}
                onChange={e =>
                  setFormData({
                    ...formData,
                    maxUsers: parseInt(e.target.value),
                  })
                }
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="domain" className="text-right">
                Domain
              </Label>
              <Input
                id="domain"
                value={formData.domain}
                onChange={e => handleDomainChange(e.target.value)}
                placeholder="example.com"
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="isPrimary" className="text-right">
                Primary Domain
              </Label>
              <div className="flex items-center space-x-2 col-span-3">
                <Switch
                  id="isPrimary"
                  checked={formData.isPrimary}
                  onCheckedChange={checked =>
                    setFormData({ ...formData, isPrimary: checked })
                  }
                />
                <span>{formData.isPrimary ? 'Yes' : 'No'}</span>
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="domainStatus" className="text-right">
                Domain Status
              </Label>
              <div className="flex items-center space-x-2 col-span-3">
                <Switch
                  id="domainStatus"
                  checked={formData.domainStatus === 'active'}
                  onCheckedChange={checked =>
                    setFormData({
                      ...formData,
                      domainStatus: checked ? 'active' : 'inactive',
                    })
                  }
                />
                <span>
                  {formData.domainStatus === 'active' ? 'Active' : 'Inactive'}
                </span>
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button type="submit">Add Enterprise</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
