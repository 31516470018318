import { ReactNode } from 'react';
import { cn } from '@/lib/utils';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import { SidebarProvider, useSidebar } from '@/components/ui/sidebar';

interface MainLayoutProps {
  children?: ReactNode;
}

function SidebarContent({ children }: { children: ReactNode }) {
  return (
    <div className="flex-1 bg-background">
      <main className="p-4 pt-16 md:pt-8">{children}</main>
    </div>
  );
}

export default function MainLayout({ children }: MainLayoutProps) {
  return (
    <SidebarProvider defaultOpen>
      <div
        className="h-svh grid"
        style={{
          gridTemplateColumns: 'auto 1fr',
          transition: 'grid-template-columns 300ms ease-in-out',
        }}
      >
        <Sidebar />
        <SidebarContent>{children || <Outlet />}</SidebarContent>
      </div>
    </SidebarProvider>
  );
}
