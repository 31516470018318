import { debugConfig } from './debug-config';

export const debugLog = {
  auth: (message: string, data?: any) => {
    if (debugConfig.enableAuthDebug) {
      console.group(`🔐 Auth: ${message}`);
      if (data) {
        console.log(JSON.stringify(data, null, 2));
      }
      console.groupEnd();
    }
  },

  request: (method: string, url: string, headers: HeadersInit, body?: any) => {
    if (debugConfig.enableApiDebug) {
      console.group(`🌐 Request: ${method} ${url}`);
      console.log('Headers:', JSON.stringify(headers, null, 2));
      if (body) {
        console.log('Body:', JSON.stringify(body, null, 2));
      }
      console.groupEnd();
    }
  },

  response: (url: string, status: number, headers: Headers, body: string) => {
    if (debugConfig.enableApiDebug) {
      console.group(`📥 Response: ${status} ${url}`);
      console.log('Headers:', Object.fromEntries([...headers.entries()]));
      try {
        const json = JSON.parse(body);
        console.log('Body:', JSON.stringify(json, null, 2));
      } catch {
        console.log('Body:', body);
      }
      console.groupEnd();
    }
  },

  error: (message: string, error: any) => {
    if (debugConfig.enableApiDebug || debugConfig.enableAuthDebug) {
      console.group(`❌ Error: ${message}`);
      if (error instanceof Error) {
        console.error('Message:', error.message);
        console.error('Stack:', error.stack);
      } else {
        console.error(error);
      }
      console.groupEnd();
    }
  },

  storage: (action: 'get' | 'set' | 'remove', key: string, value?: any) => {
    if (debugConfig.enableAuthDebug) {
      console.group(`💾 Storage: ${action.toUpperCase()} ${key}`);
      if (value !== undefined) {
        console.log('Value:', value);
      }
      console.groupEnd();
    }
  },

  cookies: () => {
    if (debugConfig.enableApiDebug) {
      console.group('🍪 Cookies');
      console.table(document.cookie.split(';').reduce((acc, cookie) => {
        const [key, value] = cookie.trim().split('=');
        return { ...acc, [key]: value };
      }, {}));
      console.groupEnd();
    }
  },
};
