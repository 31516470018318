import { useState, useCallback } from 'react';
import { useToast } from '@/components/ui/use-toast';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Upload, Trash2, FileText, Loader2 } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  useHSDocuments,
  useImportHSCodes,
  useDeleteHSDocument,
} from '@/lib/api/services/hs-lookup';
import { COUNTRIES, HS_VERSIONS } from '@/lib/api/types/hs-lookup';

export default function HSLookupSettings() {
  const { toast } = useToast();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [headerRow, setHeaderRow] = useState<string>('6');
  const [country, setCountry] = useState<string>('');
  const [version, setVersion] = useState<string>('2022');

  const { data: documents = [], isLoading } = useHSDocuments();
  const importMutation = useImportHSCodes();
  const deleteMutation = useDeleteHSDocument();

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    validateAndSetFiles(files);
  }, []);

  const handleFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files ? Array.from(e.target.files) : [];
      validateAndSetFiles(files);
    },
    []
  );

  const validateAndSetFiles = (files: File[]) => {
    const allowedTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'text/csv',
    ];

    const validFiles = files.filter(file => allowedTypes.includes(file.type));
    const invalidFiles = files.filter(
      file => !allowedTypes.includes(file.type)
    );

    if (invalidFiles.length > 0) {
      toast({
        title: 'Invalid file type(s)',
        description:
          'Some files were skipped. Please upload only Excel or CSV files.',
        variant: 'destructive',
      });
    }

    setSelectedFiles(prevFiles => [...prevFiles, ...validFiles]);
  };

  const removeFile = (index: number) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0 || !country) {
      toast({
        title: 'Missing information',
        description: 'Please select files and country code',
        variant: 'destructive',
      });
      return;
    }

    let successCount = 0;
    let failCount = 0;

    try {
      // Process files sequentially
      for (const file of selectedFiles) {
        try {
          const response = await importMutation.mutateAsync({
            file,
            country,
            version,
            headerRow,
          });

          successCount++;
          toast({
            title: `Imported ${file.name}`,
            description: `Successfully imported ${response.stats.successful} HS codes`,
          });

          // Small delay between files to prevent overwhelming the server
          await new Promise(resolve => setTimeout(resolve, 500));
        } catch (error) {
          failCount++;
          toast({
            title: `Failed to import ${file.name}`,
            description:
              error instanceof Error ? error.message : 'An error occurred',
            variant: 'destructive',
          });
        }
      }

      if (successCount > 0) {
        setSelectedFiles([]);
      }
    } catch (error) {
      toast({
        title: 'Upload failed',
        description:
          error instanceof Error ? error.message : 'An error occurred',
        variant: 'destructive',
      });
    }
  };

  const handleDelete = async (documentId: string) => {
    try {
      const [country, version] = documentId.split('-');
      await deleteMutation.mutateAsync({ country, version });
      toast({
        title: 'Document deleted',
        description: 'The document has been deleted successfully',
      });
    } catch (error) {
      toast({
        title: 'Delete failed',
        description:
          error instanceof Error ? error.message : 'An error occurred',
        variant: 'destructive',
      });
    }
  };

  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-lg font-medium">Import HS Codes</h3>
        <p className="text-sm text-muted-foreground">
          Upload Excel files containing HS codes. Each file should have columns
          for 'Chapter', 'Heading', 'HS Code', 'Export Statistical Item', 'UQ',
          and 'Description'.
        </p>
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="country">Country Code</Label>
        <Select value={country} onValueChange={setCountry}>
          <SelectTrigger>
            <SelectValue placeholder="Select country" />
          </SelectTrigger>
          <SelectContent>
            {Object.entries(COUNTRIES).map(([code, name]) => (
              <SelectItem key={code} value={code}>
                {name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="version">HS Version</Label>
        <Select value={version} onValueChange={setVersion}>
          <SelectTrigger>
            <SelectValue placeholder="Select version" />
          </SelectTrigger>
          <SelectContent>
            {Object.entries(HS_VERSIONS).map(([code, name]) => (
              <SelectItem key={code} value={code}>
                {name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="header-row">Header Row</Label>
        <Input
          id="header-row"
          type="number"
          min="1"
          value={headerRow}
          onChange={e => setHeaderRow(e.target.value)}
          className="w-24"
        />
      </div>

      <div
        className={cn(
          'border-2 border-dashed rounded-lg p-6 text-center cursor-pointer transition-colors',
          isDragging
            ? 'border-primary bg-primary/10'
            : 'border-muted-foreground/25'
        )}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => document.getElementById('file-upload')?.click()}
      >
        <input
          type="file"
          id="file-upload"
          className="hidden"
          onChange={handleFileSelect}
          accept=".xlsx,.xls,.csv"
          multiple
        />
        <Upload className="w-8 h-8 mx-auto mb-2 text-muted-foreground" />
        <p className="text-sm text-muted-foreground mb-1">
          Drag and drop your files here, or click to select
        </p>
        <p className="text-xs text-muted-foreground">
          Supports Excel files (.xlsx, .xls) and CSV files
        </p>
      </div>

      {selectedFiles.length > 0 && (
        <div className="space-y-2">
          {selectedFiles.map((file, index) => (
            <div
              key={index}
              className="flex items-center justify-between rounded-lg border p-3"
            >
              <div className="flex items-center space-x-3">
                <FileText className="h-5 w-5 text-muted-foreground" />
                <div>
                  <p className="font-medium">{file.name}</p>
                  <p className="text-sm text-muted-foreground">
                    {(file.size / 1024).toFixed(1)} KB
                  </p>
                </div>
              </div>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => removeFile(index)}
                disabled={importMutation.isLoading}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          ))}

          <Button
            onClick={handleUpload}
            disabled={importMutation.isLoading || !country}
            className="w-full"
          >
            {importMutation.isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Importing...
              </>
            ) : (
              <>
                <Upload className="mr-2 h-4 w-4" />
                Import Files
              </>
            )}
          </Button>
        </div>
      )}

      <div className="space-y-4">
        <h3 className="text-lg font-medium">Imported Documents</h3>
        {isLoading ? (
          <div className="flex justify-center py-4">
            <Loader2 className="h-8 w-8 animate-spin" />
          </div>
        ) : documents.length === 0 ? (
          <p className="text-sm text-muted-foreground">No documents imported</p>
        ) : (
          <ScrollArea className="h-[300px] rounded-md border p-4">
            <div className="space-y-2">
              {documents.map(doc => (
                <div
                  key={doc.id}
                  className="flex items-center justify-between rounded-lg border p-3"
                >
                  <div className="space-y-1">
                    <p className="font-medium">{doc.name}</p>
                    <p className="text-sm text-muted-foreground">
                      {doc.type} - Created{' '}
                      {new Date(doc.created_at).toLocaleDateString()}
                    </p>
                  </div>
                  <Button
                    variant="destructive"
                    size="sm"
                    onClick={() => handleDelete(doc.id)}
                    disabled={deleteMutation.isLoading}
                  >
                    {deleteMutation.isLoading ? (
                      <Loader2 className="h-4 w-4 animate-spin" />
                    ) : (
                      'Delete'
                    )}
                  </Button>
                </div>
              ))}
            </div>
          </ScrollArea>
        )}
      </div>
    </div>
  );
}
