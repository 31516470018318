import {
  Download,
  Loader2,
  Trash2,
  ChevronLeft,
  ChevronRight,
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { ConversionJob } from '@/types/conversion';
import { getStatusConfig } from '@/utils/conversion';
import { format } from 'date-fns';

interface ConversionsListProps {
  jobs: ConversionJob[];
  isLoading: boolean;
  isDownloading: boolean;
  isDeletingId: string | null;
  onDownload: (jobId: string) => void;
  onDelete: (jobId: string) => void;
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
}

export function ConversionsList({
  jobs,
  isLoading,
  isDownloading,
  isDeletingId,
  onDownload,
  onDelete,
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
}: ConversionsListProps) {
  if (isLoading) {
    return (
      <div className="flex justify-center py-4">
        <Loader2 className="h-6 w-6 animate-spin" />
      </div>
    );
  }

  if (!jobs.length) {
    return (
      <p className="text-center text-gray-500 py-4">No recent conversions</p>
    );
  }

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="space-y-4">
      <ScrollArea className="h-[300px]">
        <div className="space-y-4">
          <div className="grid grid-cols-[2fr,1fr,1fr,1fr] gap-4 px-2 py-2 bg-muted text-xs font-medium">
            <div>File</div>
            <div>Date</div>
            <div>Time</div>
            <div className="text-right">Actions</div>
          </div>

          {jobs.map(job => {
            const statusConfig = getStatusConfig(job.status);
            const isProcessing = [
              'processing',
              'pending',
              'uploading',
            ].includes(job.status);
            const createdAt = new Date(job.createdAt);

            return (
              <div
                key={job.id}
                className="grid grid-cols-[2fr,1fr,1fr,1fr] gap-4 items-center border-b pb-4"
              >
                <div>
                  <p className="text-sm font-medium">{job.originalFilename}</p>
                  <p
                    className={cn(
                      'text-xs flex items-center gap-2',
                      statusConfig.color
                    )}
                  >
                    {isProcessing && (
                      <Loader2 className="h-4 w-4 animate-spin" />
                    )}
                    {statusConfig.text}
                  </p>
                  {job.error && job.status !== 'completed' && (
                    <p className="text-xs text-red-500">{job.error}</p>
                  )}
                </div>

                <div className="text-xs text-muted-foreground">
                  {format(createdAt, 'dd/MM/yyyy')}
                </div>

                <div className="text-xs text-muted-foreground">
                  {format(createdAt, 'HH:mm:ss')}
                </div>

                <div className="flex space-x-2 justify-end">
                  {job.status === 'completed' && (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => onDownload(job.id)}
                      disabled={isDownloading}
                    >
                      {isDownloading ? (
                        <Loader2 className="h-4 w-4 animate-spin" />
                      ) : (
                        <Download className="h-4 w-4" />
                      )}
                    </Button>
                  )}
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => onDelete(job.id)}
                    disabled={isDeletingId === job.id}
                  >
                    {isDeletingId === job.id ? (
                      <Loader2 className="h-4 w-4 animate-spin" />
                    ) : (
                      <Trash2 className="h-4 w-4" />
                    )}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </ScrollArea>

      {totalPages > 1 && (
        <div className="flex items-center justify-between pt-4">
          <div className="text-sm text-muted-foreground">
            Page {currentPage} of {totalPages}
          </div>
          <div className="flex space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
