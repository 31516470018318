import React from 'react';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@/components/ui/card';
import { useAuth } from '@/hooks/useAuth';
import { FileUploadArea } from '@/components/hs-lookup/FileUploadArea';
import { Upload, Loader2 } from 'lucide-react';
import { UseFormReturn } from 'react-hook-form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

interface BulkUploadFormValues {
  origin: string;
  destination: string;
  company_name: string;
  industry: string;
  sector: string;
  hs_digits: string;
}

interface BulkUploadJob {
  id: string;
  status: 'pending' | 'processing' | 'completed' | 'failed';
  total_rows: number;
  processed_rows: number;
  error_message?: string | null;
  download_url?: string;
  created_at: string;
  updated_at: string;
  original_filename: string;
}

interface BulkUploadSectionProps {
  selectedFile: File | null;
  isBulkLoading: boolean;
  form: UseFormReturn<BulkUploadFormValues>;
  onFileSelect: (file: File | null) => void;
  onBulkUpload: () => Promise<void>;
  recentBulkUploads: BulkUploadJob[];
  onDownload: (jobId: string) => Promise<void>;
  onDelete: (jobId: string) => Promise<void>;
}

export function BulkUploadSection({
  selectedFile,
  isBulkLoading,
  form,
  onFileSelect,
  onBulkUpload,
  recentBulkUploads,
  onDownload,
  onDelete,
}: BulkUploadSectionProps) {
  const { isAuthenticated } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onBulkUpload();
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Bulk HS Code Lookup</CardTitle>
        <CardDescription>
          Upload Excel files to process multiple HS code lookups at once
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-6">
          <FileUploadArea
            selectedFile={selectedFile}
            isUploading={isBulkLoading}
            onFileSelect={onFileSelect}
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="origin">Origin Country</Label>
              <Input
                id="origin"
                placeholder="e.g. CN"
                {...form.register('origin')}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="destination">Destination Country</Label>
              <Input
                id="destination"
                placeholder="e.g. AU"
                {...form.register('destination')}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="company_name">Company Name</Label>
              <Input
                id="company_name"
                placeholder="Enter company name"
                {...form.register('company_name')}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="industry">Industry</Label>
              <Input
                id="industry"
                placeholder="e.g. Manufacturing"
                {...form.register('industry')}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="sector">Sector</Label>
              <Input
                id="sector"
                placeholder="e.g. Electronics"
                {...form.register('sector')}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="hs_digits">HS Code Length</Label>
              <Select
                value={form.watch('hs_digits')}
                onValueChange={value => form.setValue('hs_digits', value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select HS code length" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="6">6 digits</SelectItem>
                  <SelectItem value="8">8 digits</SelectItem>
                  <SelectItem value="10">10 digits</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          <Button
            type="submit"
            disabled={!selectedFile || isBulkLoading}
            className="w-full"
          >
            {isBulkLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Processing...
              </>
            ) : (
              <>
                <Upload className="mr-2 h-4 w-4" />
                Upload for Processing {selectedFile && `(${selectedFile.name})`}
              </>
            )}
          </Button>
        </form>

        {recentBulkUploads.length > 0 && (
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-4">Recent Uploads</h3>
            <div className="space-y-4">
              {recentBulkUploads.map(job => (
                <div
                  key={job.id}
                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
                >
                  <div>
                    <p className="font-medium">{job.original_filename}</p>
                    <p className="text-sm text-gray-500">
                      {job.processed_rows} / {job.total_rows} rows
                    </p>
                    <p className="text-sm text-gray-500">
                      Status: {job.status}
                    </p>
                  </div>
                  <div className="flex gap-2">
                    {job.status === 'completed' && (
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => onDownload(job.id)}
                      >
                        Download
                      </Button>
                    )}
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => onDelete(job.id)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
