import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import type {
  DocumentTemplate,
  UploadTemplateResponse,
  DeleteTemplateResponse,
} from '../types/document-check';

const TEMPLATES_KEY = 'document-check-templates';

export function useDocumentTemplates() {
  const api = useApi();

  return useQuery({
    queryKey: [TEMPLATES_KEY],
    queryFn: async () => {
      const response = await api.get<{
        success: boolean;
        templates: DocumentTemplate[];
      }>('/api/doc-check/templates');
      if (!response.success) {
        throw new Error('Failed to fetch document templates');
      }
      return response.templates;
    },
  });
}

export function useUploadDocumentTemplate() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append('file', file);

      const response = await api.post<UploadTemplateResponse>(
        '/api/doc-check/templates/upload',
        formData
      );
      if (!response.success) {
        throw new Error('Failed to upload document template');
      }
      return response.template;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TEMPLATES_KEY] });
    },
  });
}

export function useDeleteDocumentTemplate() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (templateId: number) => {
      const response = await api.delete<DeleteTemplateResponse>(
        `/api/doc-check/templates/${templateId}`
      );
      if (!response.success) {
        throw new Error('Failed to delete document template');
      }
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TEMPLATES_KEY] });
    },
  });
}
