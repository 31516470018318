import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Card, CardHeader, CardContent } from '@/components/ui/card';
import { Loader2, AlertCircle, Eye, EyeOff } from 'lucide-react';
import { useEffect, useState } from 'react';
import { loginSchema, LoginFormValues } from '@/lib/validations/auth';
import { useLogin } from '@/hooks/useLogin';
import { Alert, AlertDescription } from '@/components/ui/alert';

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);

  const form = useForm<LoginFormValues>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  });

  const { isLoading, handleLogin, verifySession } = useLogin({
    onError: error => {
      form.setError('root', {
        type: 'manual',
        message: error.message,
      });
    },
  });

  // Only clear form errors when user starts typing
  useEffect(() => {
    const subscription = form.watch(() => {
      if (form.formState.errors.root) {
        form.clearErrors('root');
      }
    });
    return () => subscription.unsubscribe();
  }, [form]);

  // Verify session on mount
  useEffect(() => {
    verifySession();
  }, [verifySession]);

  const onSubmit = async (values: LoginFormValues) => {
    try {
      await handleLogin(values);
    } catch (error) {
      console.error('Login submission error:', error);
      form.setError('root', {
        type: 'manual',
        message:
          error instanceof Error
            ? error.message
            : 'An unexpected error occurred',
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-slate-50 p-4">
      <Card className="w-full max-w-[400px]">
        <CardHeader className="text-center space-y-2">
          <h1 className="text-2xl font-bold tracking-tight">
            Freight Forwarding System
          </h1>
          <p className="text-sm text-muted-foreground">
            Sign in to access your account
          </p>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-4"
              noValidate
            >
              {form.formState.errors.root && (
                <Alert variant="destructive" className="text-sm">
                  <AlertCircle className="h-4 w-4" />
                  <AlertDescription>
                    {form.formState.errors.root.message}
                  </AlertDescription>
                </Alert>
              )}
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        disabled={isLoading}
                        placeholder="Enter your email"
                        type="email"
                        autoComplete="email"
                        autoFocus
                        aria-describedby="email-error"
                      />
                    </FormControl>
                    <FormMessage id="email-error" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          {...field}
                          type={showPassword ? 'text' : 'password'}
                          disabled={isLoading}
                          placeholder="Enter your password"
                          autoComplete="current-password"
                          aria-describedby="password-error"
                        />
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                          aria-label={
                            showPassword ? 'Hide password' : 'Show password'
                          }
                        >
                          {showPassword ? (
                            <EyeOff className="h-4 w-4" />
                          ) : (
                            <Eye className="h-4 w-4" />
                          )}
                        </button>
                      </div>
                    </FormControl>
                    <FormMessage id="password-error" />
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                className="w-full bg-[#76A6F7] hover:bg-[#76A6F7]/90 text-white font-medium"
                disabled={isLoading}
                aria-label={isLoading ? 'Signing in...' : 'Sign in'}
              >
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Signing in...
                  </>
                ) : (
                  'Sign In'
                )}
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
}
