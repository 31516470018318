import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchApi, uploadFile, downloadFile } from '../utils/fetch';
import {
  ConversionJob,
  ConversionListResponse,
  ConversionUploadResponse,
  ConversionUploadParams,
} from '../types/conversion';
import { getApiUrl } from '../../config';
import { getAuthToken } from '../../auth-store';

export function useUploadForConversion() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ files }: ConversionUploadParams) => {
      const formData = new FormData();
      files.forEach(file => {
        formData.append('file', file);
      });

      return uploadFile<ConversionUploadResponse>('convert/upload', formData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['conversions'] });
    },
  });
}

export function useRecentConversions(limit = 10, offset = 0) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['conversions', limit, offset],
    queryFn: async () => {
      const response = await fetchApi<ConversionListResponse>(
        `convert/list?limit=${limit}&offset=${offset}`
      );

      return {
        ...response,
        jobs: response.jobs?.map((job: ConversionJob) => ({
          ...job,
          status: job.status || 'pending',
          downloadUrl:
            job.status === 'completed'
              ? `${getApiUrl()}/convert/${job.id}/download`
              : null,
        })),
      };
    },
    refetchInterval: data => {
      const hasActiveJobs = data?.jobs?.some(
        job => job.status === 'pending' || job.status === 'processing'
      );
      return hasActiveJobs ? 2000 : false;
    },
    refetchOnWindowFocus: true,
    staleTime: 0,
    onSuccess: data => {
      data.jobs?.forEach(job => {
        queryClient.setQueryData(['conversion', job.id], job);
      });

      const oldData = queryClient.getQueryData([
        'conversions',
        limit,
        offset,
      ]) as ConversionListResponse;

      if (oldData?.jobs) {
        const hasStatusChanged = data.jobs?.some(newJob => {
          const oldJob = oldData.jobs.find(j => j.id === newJob.id);
          return oldJob && oldJob.status !== newJob.status;
        });
        if (hasStatusChanged) {
          queryClient.invalidateQueries({ queryKey: ['conversions'] });
        }
      }
    },
  });
}

export function useDeleteConversion() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (jobId: string) => {
      await fetchApi(`convert/${jobId}`, {
        method: 'DELETE',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['conversions'] });
    },
  });
}

export async function downloadConversionResult(jobId: string): Promise<void> {
  const { blob, filename } = await downloadFile(`convert/${jobId}/download`);
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}
