import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Checkbox } from '@/components/ui/checkbox';
import { UserPlus } from 'lucide-react';
import { UserFormData, USER_ROLES } from '@/types/user';
import { PERMISSIONS, Permission, formatPermission } from '@/lib/permissions';

interface AddUserDialogProps {
  onAddUser: (userData: UserFormData) => Promise<boolean>;
}

const initialFormData: UserFormData = {
  username: '',
  email: '',
  password: '',
  role: 'user' as const,
  name: '',
  permissions: [],
};

export function AddUserDialog({ onAddUser }: AddUserDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState<UserFormData>(initialFormData);

  const handleInputChange = (
    field: keyof UserFormData,
    value: string | Permission[]
  ) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handlePermissionChange = (permission: Permission) => {
    setFormData(prev => ({
      ...prev,
      permissions: prev.permissions.includes(permission)
        ? prev.permissions.filter(p => p !== permission)
        : [...prev.permissions, permission],
    }));
  };

  const handleSubmit = async () => {
    const success = await onAddUser(formData);
    if (success) {
      setFormData(initialFormData);
      setIsOpen(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button>
          <UserPlus className="mr-2 h-4 w-4" />
          Add User
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle>Add New User</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div className="grid gap-2">
            <Label>Username</Label>
            <Input
              placeholder="Enter username"
              value={formData.username}
              onChange={e => handleInputChange('username', e.target.value)}
            />
          </div>
          <div className="grid gap-2">
            <Label>Email</Label>
            <Input
              type="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={e => handleInputChange('email', e.target.value)}
            />
          </div>
          <div className="grid gap-2">
            <Label>Password</Label>
            <Input
              type="password"
              placeholder="Enter password"
              value={formData.password}
              onChange={e => handleInputChange('password', e.target.value)}
            />
          </div>
          <div className="grid gap-2">
            <Label>Name</Label>
            <Input
              placeholder="Enter name"
              value={formData.name}
              onChange={e => handleInputChange('name', e.target.value)}
            />
          </div>
          <div className="grid gap-2">
            <Label>Role</Label>
            <Select
              value={formData.role}
              onValueChange={value => handleInputChange('role', value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select role" />
              </SelectTrigger>
              <SelectContent>
                {USER_ROLES.map(role => (
                  <SelectItem key={role} value={role}>
                    {role.charAt(0).toUpperCase() + role.slice(1)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="grid gap-2">
            <Label>Permissions</Label>
            <div className="grid grid-cols-2 gap-2">
              {Object.values(PERMISSIONS).map(permission => (
                <div key={permission} className="flex items-center space-x-2">
                  <Checkbox
                    id={permission}
                    checked={formData.permissions.includes(permission)}
                    onCheckedChange={() => handlePermissionChange(permission)}
                  />
                  <label
                    htmlFor={permission}
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    {formatPermission(permission)}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <Button
            className="w-full"
            onClick={handleSubmit}
            disabled={
              !formData.username ||
              !formData.email ||
              !formData.password ||
              !formData.role ||
              !formData.name
            }
          >
            Add User
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
