import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../../../hooks/useApi';
import type {
  HSLookupFormValues,
  HSLookupResponse,
  HSDocument,
  HSImportRequest,
  HSImportResponse,
  HSDeleteRequest,
  HSDocumentsResponse,
  BulkHSLookupRequest,
  BulkHSLookupResponse,
  BulkHSLookupStatus,
  RecentBulkUpload,
} from '../types/hs-lookup';

const HS_DOCUMENTS_KEY = ['hs-documents'] as const;
const RECENT_UPLOADS_KEY = ['recent-uploads'] as const;

export function useHSLookup() {
  const api = useApi();

  return useMutation({
    mutationFn: async (values: HSLookupFormValues) => {
      const endpoint = values.jobId
        ? `/hs-lookup/status/${values.jobId}`
        : '/hs-lookup/search';

      const response = await api.post<HSLookupResponse>(endpoint, values);
      return response;
    },
  });
}

export function useBulkHSLookup() {
  const api = useApi();

  return useMutation({
    mutationFn: async ({ file, hsDigits }: BulkHSLookupRequest) => {
      const formData = new FormData();
      formData.append('file', file);
      if (hsDigits) {
        formData.append('hsDigits', hsDigits);
      }

      const response = await api.post<BulkHSLookupResponse>(
        '/hs-lookup/bulk',
        formData
      );
      return response;
    },
  });
}

export function useBulkHSLookupStatus() {
  const api = useApi();

  return useMutation({
    mutationFn: async (jobId: string) => {
      const response = await api.get<BulkHSLookupStatus>(
        `/hs-lookup/bulk/status/${jobId}`
      );
      return response;
    },
  });
}

export function useHSDocuments() {
  const api = useApi();

  return useQuery({
    queryKey: HS_DOCUMENTS_KEY,
    queryFn: async () => {
      const response = await api.get<HSDocumentsResponse>('/settings/hs-codes');
      return response.documents;
    },
  });
}

export function useImportHSCodes() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      file,
      country,
      version,
      headerRow,
    }: HSImportRequest) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('country', country);
      formData.append('version', version);
      formData.append('headerRow', headerRow ? 'true' : 'false');

      const response = await api.put<HSImportResponse>(
        '/settings/hs-codes/import',
        formData
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: HS_DOCUMENTS_KEY });
    },
  });
}

export function useDeleteHSDocument() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ country, version }: HSDeleteRequest) => {
      const response = await api.delete<{ success: boolean }>(
        `/settings/hs-codes?country=${country}&version=${version}`
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: HS_DOCUMENTS_KEY });
    },
  });
}

export function useRecentBulkUploads() {
  const api = useApi();

  return useQuery({
    queryKey: RECENT_UPLOADS_KEY,
    queryFn: async () => {
      const response = await api.get<{ uploads: RecentBulkUpload[] }>(
        '/hs-lookup/bulk/recent'
      );
      return response.uploads;
    },
  });
}
