import { useState } from 'react';
import { useToast } from '@/hooks/use-toast';
import type { FeedbackData } from '@/lib/api/types/hs-lookup';
import { hsLookupService } from '../services/api';
import { createFeedbackPayload } from '../utils';

const DEFAULT_FEEDBACK_DATA: FeedbackData = {
  accuracy: 3,
  codeSpecificity: 3,
  reasoningClarity: 3,
  tradingRequirements: 3,
  alternativesQuality: 3,
  comments: '',
};

export const useFeedback = (lookup_id?: string) => {
  const { toast } = useToast();
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [feedbackData, setFeedbackData] = useState<FeedbackData>(
    DEFAULT_FEEDBACK_DATA
  );

  const handleFeedbackSubmit = async () => {
    try {
      if (!lookup_id) {
        throw new Error('No lookup ID available');
      }

      const payload = createFeedbackPayload(
        lookup_id,
        {
          accuracy: feedbackData.accuracy,
          codeSpecificity: feedbackData.codeSpecificity,
          reasoningClarity: feedbackData.reasoningClarity,
          tradingRequirements: feedbackData.tradingRequirements,
          alternativesQuality: feedbackData.alternativesQuality,
        },
        feedbackData.comments
      );

      const data = await hsLookupService.submitFeedback(payload);

      if (!data.success) {
        throw new Error(data.error || 'Failed to save feedback');
      }

      setShowFeedbackDialog(false);
      toast({
        title: 'Thank you for your feedback!',
        description: 'Your feedback helps improve our classification accuracy.',
      });
    } catch (error) {
      console.error('Error saving feedback:', error);
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to save feedback',
        variant: 'destructive',
      });
    }
  };

  const updateFeedbackMetric = (
    metric: keyof Omit<FeedbackData, 'comments'>,
    value: number
  ) => {
    setFeedbackData(prev => ({
      ...prev,
      [metric]: value,
    }));
  };

  const updateComments = (comments: string) => {
    setFeedbackData(prev => ({
      ...prev,
      comments,
    }));
  };

  return {
    showFeedbackDialog,
    feedbackData,
    setShowFeedbackDialog,
    handleFeedbackSubmit,
    updateFeedbackMetric,
    updateComments,
  };
};
